import { useQuery } from 'react-query';
import { fetchSensorTypeUsageCashiersData } from '@/services';
import { useSession } from '@/contexts';

type QueryParams = {
    startDate: string;
    endDate: string;
    storeId: string | undefined;
};

export default function useSensorTypeUsageCashiersData(params: QueryParams) {
    const { session, user } = useSession();
    const { startDate, endDate, storeId } = params;

    if (!storeId) {
        throw new Error('No Store found');
    }

    if (!session) {
        throw new Error('No session found');
    }

    const accessToken = session?.access_token;
    return useQuery(
        ['sensorTypeUsageCashiersData', startDate, endDate, storeId], // Unique query key
        () =>
            fetchSensorTypeUsageCashiersData({
                startDate,
                endDate,
                storeId,
                accessToken
            }),
        {
            enabled:
                !!accessToken &&
                storeId !== undefined &&
                storeId !== null &&
                !!user, // Only run if accessToken is available
            // staleTime: 5 * 60 * 1000, // Optional: data is considered fresh for 5 minutes
            // cacheTime: 10 * 60 * 1000 // Optional: cache the data for 10 minutes
            staleTime: Infinity // Mark the data as always fresh, no need for automatic refetch
        }
    );
}
