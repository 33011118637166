import { BarChartSkeleton, Chart } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import { SMART_SENSORS_TYPES } from '@/hooks/data/Constants';
import { useUsedSensorsData } from '@/hooks/data/analytics/inventory/useUsedSensorsData';
import { subSeconds } from 'date-fns';
import { dateFormatter } from '@/utils';
import { DateRange } from 'react-day-picker';

function SmartTagsInUseChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const { data, error, isLoading } = useUsedSensorsData({
        startDate: dateFormatter(dateRange?.from),
        endDate: dateRange?.to
            ? dateFormatter(subSeconds(dateRange.to, 1))
            : '',
        storeId: storeId!
    });

    if (isLoading || !storeId)
        return (
            <div className="mt-6">
                <BarChartSkeleton />
            </div>
        );
    if (error) return <p>Error: </p>;

    return (
        <div className="relative pt-2">
            <Chart
                type={'bar'}
                data={data?.sensorValues || []}
                options={getChartOptions(SMART_SENSORS_TYPES, 'bar', true)}
            />
        </div>
    );
}

export default SmartTagsInUseChart;
