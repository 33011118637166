import React from 'react';
import { Button } from '../ui/button';
import { ArrowUpDown, ArrowUp, ArrowDown } from 'lucide-react';

function SortableHeader({ column, title }: { column: any; title: string }) {
    return (
        <Button
            variant="ghost"
            onClick={() => {
                if (!column.getIsSorted()) {
                    column.toggleSorting(column.getIsSorted() === 'desc');
                } else if (column.getIsSorted() === 'asc') {
                    column.toggleSorting(column.getIsSorted() === 'asc');
                } else {
                    column.clearSorting();
                }
            }}
        >
            {title}
            {!column.getIsSorted() && <ArrowUpDown className="h-4 w-4 " />}
            {column.getIsSorted() === 'asc' && (
                <ArrowUp className="h-4 w-4 text-green-500" />
            )}
            {column.getIsSorted() === 'desc' && (
                <ArrowDown className="h-4 w-4 text-red-500" />
            )}
        </Button>
    );
}

export default SortableHeader;
