import { Outlet } from 'react-router-dom';
import Header from './Header';
import { useSession, useStoreContext } from '@/contexts';
import AppSidebar from './Sidebar';

import { SidebarInset, SidebarProvider } from '@/components/ui/sidebar';
import { StoreType } from '@/types';
import ReactLoading from 'react-loading';
import themes from '@/theme';

function Layout() {
    const { user } = useSession();
    const { stores, isLoading } = useStoreContext();
    const merchantId = user?.merchantId;
    const themeMerchantId = ['0', '5', '9'].includes(merchantId as string)
        ? merchantId
        : '0';
    if (isLoading) {
        return (
            <div className="flex flex-col justify-center items-center w-full h-screen ">
                <ReactLoading
                    type="cubes"
                    width={100}
                    // height={10}
                    color={
                        themes[themeMerchantId as keyof typeof themes]
                            .primary_200
                    }
                />
                <span className="text-lg text-primary-400 mt-3">Loading</span>
            </div>
        );
    }
    return (
        <>
            <SidebarProvider>
                <AppSidebar
                    merchantId={themeMerchantId}
                    stores={stores as StoreType[]}
                    storesAccess={user?.storesAccess}
                />
                <SidebarInset>
                    <Header userName={user?.fullName || ''} />
                    <div className="flex flex-1 flex-col gap-2 p-4 pt-0">
                        <Outlet />
                    </div>
                </SidebarInset>
            </SidebarProvider>
        </>
    );
}

export default Layout;
