import { Button } from '@/components/ui/button';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { FloatingLabelInput } from '@/components/ui/floatingLabelInput';
import { ComboBoxResponsive } from '../ui/combobox';
import { useEffect } from 'react';
import { DetacherUnitType, DetacherUnitUpdateType } from '@/types';
import { FormModal } from '@/components';
import { useDetacherUnit } from '@/hooks';
import { DU_TYPES } from '@/hooks/data/Constants';

const formSchema = z.object({
    description: z.string().min(2).max(50),
    foreignCheckoutId: z.string().max(20).optional(),
    posType: z.enum(DU_TYPES as [string, ...string[]]).optional()
});

function DetacherUnitForm({
    data,
    openForm,
    setOpenForm
}: {
    data?: DetacherUnitType | undefined;
    openForm: boolean;
    setOpenForm: (open: boolean) => void;
}) {
    const { t } = useTranslation();

    const { updateDetacherUnit } = useDetacherUnit({
        storeId: data?.storeId.toString()
    });

    const transformedDetacherTypes = DU_TYPES.map((type) => ({
        value: type,
        label: type.replace(/&/g, ' & ')
    }));

    // Set form values when the form is opened with data (update mode)
    useEffect(() => {
        if (data) {
            const typeValue = transformedDetacherTypes.find(
                (item) => item.value === data?.posType
            );
            form.setValue('description', data?.description);
            form.setValue('posType', typeValue?.value as string);
            form.setValue('foreignCheckoutId', data?.foreignCheckoutId || '');
        }
    }, [data]);

    // Reset form when the form is closed
    useEffect(() => {
        if (!openForm) {
            form.reset();
        }
    }, [openForm]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            description: '',
            posType: '',
            foreignCheckoutId: ''
        }
    });

    function onSubmit(values: z.infer<typeof formSchema>) {
        const detachedUnitObject: DetacherUnitUpdateType = {
            description: values.description,
            foreignCheckoutId: values.foreignCheckoutId,
            posType: values.posType as
                | 'CCO'
                | 'SCO'
                | 'Scan & Go'
                | 'Service Desk',
            name: data?.name
        };

        // Check if the store data has been updated (no changes detected)
        const isUpdated = Object.keys(values).some(
            (key) =>
                values[key as keyof typeof DetacherUnitForm] !==
                data![key as keyof DetacherUnitType]
        );
        if (!isUpdated) {
            setOpenForm(false);
            console.log('No changes detected');
            return;
        }
        updateDetacherUnit.mutate(detachedUnitObject, {
            onSuccess: () => {
                setOpenForm(false);
            }
        });
    }

    return (
        <FormModal
            open={openForm}
            setOpen={setOpenForm}
            formTitle={t('detacherUnit.editDetacherUnit')}
            hideCreateButton={true}
        >
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-4 mt-4"
                >
                    <FormField
                        control={form.control}
                        name="description"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="description"
                                    label={t('detacherUnit.description') + ' *'}
                                    type="text"
                                    required
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="posType"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    // disabled={true}
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    statuses={transformedDetacherTypes}
                                    showPrefixIcon={false}
                                    placeholder={t('detacherUnit.selectType')}
                                    label={t('detacherUnit.type')}
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="foreignCheckoutId"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="foreignCheckoutId"
                                    label={t('stores.foreignId')}
                                    type="text"
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button
                        disabled={!!data && !form.formState.isDirty}
                        type="submit"
                    >
                        {t('common.submit')}
                    </Button>
                </form>
            </Form>
        </FormModal>
    );
}

export default DetacherUnitForm;
