import {
    SMART_SENSORS_OBJECT_KEYS,
    SMART_SENSORS_TYPES
} from '@/hooks/data/Constants';
import { serviceAPI } from '@/utils';

type ParamType = {
    startDate: string;
    endDate: string;
    storeId: string;
    accessToken: string;
    labelName?: string;
};

export async function fetchUsedSensorsData({
    startDate,
    endDate,
    storeId,
    labelName
}: ParamType) {
    const res = await serviceAPI('analytic').get(
        `/usedStoreSensors?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
    );

    const sensorData = await res.data;

    const sensorValues = [
        {
            name: labelName,
            data: [
                sensorData.Bottle_Sensors,
                sensorData.Fashion_Sensors,
                sensorData.Mini_Safer_Sensors,
                sensorData.Safer_Sensors,
                sensorData.Spider_Sensors
                // sensorData.Other_Sensors
            ]
        }
    ];

    return { sensorValues, ...sensorData };
}

// Assigned vs Used sensors
export async function fetchAssignedVsUsedSensorsData({
    startDate,
    endDate,
    storeId
}: ParamType) {
    const res = await serviceAPI('analytic').get(
        `/sensorUsageStore?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
    );

    const sensorData = await res.data;

    const sensorValues = [
        {
            data: sensorData.All_Sensors,
            name: 'All Sensors'
        },
        sensorData.Used_Sensors
    ];

    console.log(sensorValues);

    return { sensorValues, ...sensorData };
}

// Smart Tag Unlock Trends
export async function fetchSmartTagUnlockTrendsData({
    startDate,
    endDate,
    storeId
}: ParamType) {
    const res = await serviceAPI('analytic').get(
        `/usedStoreSensorsHistorical?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
    );

    const resData = await res.data;
    delete resData.Api_Code;
    delete resData.Api_Message;
    delete resData.Api_Type;

    const intervals = Object.keys(res.data);

    const smartTagsHistory = [];
    for (let i = 0; i < SMART_SENSORS_TYPES?.length; i++) {
        const name = SMART_SENSORS_TYPES[i];
        const data = [];
        for (let j = 0; j < intervals?.length; j++) {
            const interval = intervals[j];
            data.push(resData[interval][SMART_SENSORS_OBJECT_KEYS[i]]);
        }
        smartTagsHistory.push({ name, data });
    }

    return { intervals, smartTagsHistory };
}
