import { ColorType } from '@/types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Icons from 'lucide-react';
import { type LucideIcon } from 'lucide-react';
import CardSkeleton from './skeleton/CardSkeleton';
import clsx from 'clsx';

type StatCardProps = {
    icon?: LucideIcon;
    title: string;
    value: number;
    secondValue?: number | null;
    percentage?: number;
    secondPercentage?: number;
    link?: string;
    iconColor?: ColorType;
    isLoading?: boolean;
    storeId?: string;
};

function StatCard({
    icon,
    title,
    value,
    percentage,
    secondPercentage,
    link,
    iconColor = 'primary',
    isLoading = true,
    secondValue = null,
    storeId
}: StatCardProps) {
    const { t } = useTranslation();
    const Icon = icon;

    if (isLoading) {
        return <CardSkeleton />;
    }

    return (
        <div className="flex flex-col bg-white justify-start rounded-xl hover:drop-shadow-sm transition-all duration-300 ease-in-out hover:bg-neutral-50  ">
            <div className="p-5 flex w-full   flex-col ">
                <div className="flex flex-row gap-x-2 justify-start mb-4  ">
                    {Icon && (
                        <Icon
                            className={`size-4 text-${iconColor}-500`}
                            color={iconColor}
                        />
                    )}

                    <p className="text-sm uppercase tracking-wide text-gray-500 ">
                        {title}
                    </p>
                </div>
                {secondValue ? (
                    <div
                        className={clsx(
                            'flex flex-row gap-x-2 justify-evenly items-center ',
                            {
                                'h-16': !link
                            }
                        )}
                    >
                        <div className="flex flex-col gap-y-1">
                            <span className="inline-block text-xs text-slate-500">
                                {t('analytics.today')}
                            </span>
                            <div className="flex items-end justify-center  gap-x-2">
                                <h3 className=" text-2xl leading-none  font-medium ">
                                    {value}
                                </h3>
                                <span className="flex items-center gap-x-1 text-red-600">
                                    {percentage !== undefined && (
                                        <span className="inline-block text-sm">
                                            {percentage}%
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-1">
                            <span className="text-xs text-slate-500">
                                {t('analytics.currentMonth')}
                            </span>
                            <div className="flex items-end justify-center  gap-x-2">
                                <h3 className=" text-2xl leading-none  font-medium ">
                                    {secondValue}
                                </h3>
                                <span className="flex items-center gap-x-1 text-red-600">
                                    {secondPercentage !== undefined && (
                                        <span className="inline-block text-sm">
                                            {secondPercentage}%
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                ) : (
                    <div
                        className={clsx(
                            ' flex items-center justify-center   gap-x-2   ',
                            {
                                'h-16': !link
                            }
                        )}
                    >
                        <h3 className=" text-3xl leading-none  font-medium ">
                            {value}
                        </h3>
                        <span className="flex items-center gap-x-1 text-red-600">
                            {percentage !== undefined && (
                                <span className="inline-block text-lg">
                                    {percentage}%
                                </span>
                            )}
                        </span>
                    </div>
                )}
            </div>

            {link && (
                <Link
                    className="py-3 px-4 md:px-5 inline-flex justify-between items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 rounded-b-xl "
                    to={
                        !link.includes('?')
                            ? link + `?storeId=${storeId}`
                            : link + `&storeId=${storeId}`
                    }
                >
                    {t('common.moreDetails')}
                    <Icons.ChevronRightIcon className="size-4" />
                </Link>
            )}
        </div>
    );
}

export default StatCard;
