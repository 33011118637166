import { useQuery, useMutation } from 'react-query';
import { useState } from 'react';
import { OpenTagsParams, JobStatusType, ForeignNamesType } from '@/types';
import { useSession } from '@/contexts';
import { serviceAPI } from '@/utils';

export const useCloudOpening = ({ jobId }: { jobId?: string }) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { session, user } = useSession();

    // Fetch Foreign Checkout Names
    const {
        data: ForeignNames = {
            checkout_names: null,
            store_ids: []
        },
        isLoading,
        isError
    } = useQuery<ForeignNamesType>(
        ['ForeignNames'],
        async () => {
            let query = `/foreignCheckoutNames?merchantId=${user?.merchantId}`;

            const response = await serviceAPI('device').get(query);
            if (
                response.request.responseURL.startsWith(
                    process.env.CALLBACK_URL as string
                )
            ) {
                // handle the case where the user is not authenticated
                const error = new Error('Unauthorized');
                (error as any).status = 401; // Attach a 401 status
                throw error;
            }

            return response.data;
        },
        {
            enabled: !!session?.access_token && !!user,
            // staleTime: 5 * 60 * 1000,
            staleTime: Infinity,
            onError: (error: any) => {
                console.log('error:', error);
            }
        }
    );

    // Open Tags
    const {
        mutate: openTags,
        isLoading: isOpeningTags,
        isError: isErrorOpeningTags
    } = useMutation(
        (openTagParams: {
            openingType: 'proximity' | 'batch';
            storeId?: string;
            storeForeignId?: string;
            checkoutId?: string;
            checkoutName?: string;
            formType: 'withStoreId' | 'withStoreForeignId';
            idListType: 'serialNumbers' | 'deviceIds' | 'accessIds';
            serialNumbers?: string[];
            deviceIds?: string[];
            accessIds?: string[];
            receiptNumber?: string;
            receiptDate?: string;
            articleTotal?: number;
        }): Promise<any> => {
            const params: OpenTagsParams =
                openTagParams.formType === 'withStoreId'
                    ? {
                          internalStoreId: openTagParams.storeId,
                          detacherUnitId: openTagParams.checkoutId,
                          openingType: openTagParams.openingType
                      }
                    : {
                          storeId: openTagParams.storeForeignId,
                          checkoutName: openTagParams.checkoutName,
                          openingType: openTagParams.openingType
                      };

            if (openTagParams.openingType === 'batch') {
                switch (openTagParams.idListType) {
                    case 'serialNumbers':
                        params.serialNumbers = openTagParams.serialNumbers;
                        break;
                    case 'deviceIds':
                        params.deviceIds = openTagParams.deviceIds;
                        break;
                    case 'accessIds':
                        params.accessIds = openTagParams.accessIds;
                        break;
                    default:
                        break;
                }
            }

            // Optional params
            if (openTagParams.receiptNumber) {
                params.receiptNumber = openTagParams.receiptNumber;
            }
            if (openTagParams.receiptDate) {
                params.receiptDate = openTagParams.receiptDate;
            }
            if (openTagParams.articleTotal) {
                params.articleTotal = openTagParams.articleTotal;
            }

            return serviceAPI('payment').post(`/openTags`, params);
        },
        {
            onSuccess: () => {
                // queryClient.invalidateQueries(['SmartTags']);
                // setGlobalSuccess(t('smartTag.smartTagUpdateSuccessfully'));
            },
            onError: (error: any) => {
                // setGlobalError(
                //     error.response?.data?.error ??
                //         error.response?.data?.Api_Message ??
                //         'Failed to update Smart Tag'
                // );
                setErrorMessage(
                    error.response?.data?.error || 'Failed to open tags'
                );
            }
        }
    );

    // Get Job Status

    // Fetch Foreign Checkout Names
    const {
        data: JobStatus,
        isLoading: isLoadingJobStatus,
        isError: isErrorJobStatus,
        remove: removeJobStatus,
        refetch: refetchJobStatus
    } = useQuery<JobStatusType>(
        ['ForeignNames', jobId],
        async () => {
            let query = `/getJobStatus?jobId=${jobId}`;
            const response = await serviceAPI('payment').get(query);
            if (
                response.request.responseURL.startsWith(
                    process.env.CALLBACK_URL as string
                )
            ) {
                // handle the case where the user is not authenticated
                const error = new Error('Unauthorized');
                (error as any).status = 401; // Attach a 401 status
                throw error;
            }

            return response.data;
        },
        {
            enabled: !!session?.access_token && !!user && !!jobId,
            // staleTime: 5 * 60 * 1000,
            staleTime: Infinity,
            onError: (error: any) => {
                console.log('error:', error);

                // if (error.response?.status !== 401) {
                //     setErrorMessage(
                //         error.response?.data?.error ||
                //             'Failed to fetch Smart Tags'
                //     );
                // }
            }
        }
    );

    return {
        ForeignNames,
        isLoading,
        isError,
        errorMessage,
        openTags,
        isOpeningTags,
        isErrorOpeningTags,
        JobStatus,
        isLoadingJobStatus,
        removeJobStatus,
        refetchJobStatus
    };
};
