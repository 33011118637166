import { AuthContextType, UserType } from '@/types';
import { checkPermission } from '@/utils';
import config from '@/utils/configs';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export const fetchUserProfile = async (accessToken: string) => {
    const response = await fetch(
        `${config.REACT_APP_AUTH_AUTHORITY}/protocol/openid-connect/userinfo`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }
    );

    if (!response.ok) {
        throw new Error('Failed to fetch user profile');
    }

    const data = await response.json();

    // console.log('USER_data', data);
    const storeAccess = checkPermission(
        'storeManagement',
        data.dashboard_permissions
    );

    const userProfile = {
        email: data.email,
        fullName: data.name,
        preferredUsername: data.preferred_username,
        givenName: data.given_name,
        familyName: data.family_name,
        emailVerified: data.email_verified,
        locale: data.locale,
        merchantId: data.merchant_id,
        storeId: data.store_id,
        storeIds: data.store_ids,
        permissions: data.dashboard_permissions,
        stores: userStores(data),
        storesAccess: storeAccess
    };
    return userProfile;
};

const userStores = (user: UserType) => {
    const storeId = user.store_id;
    const storeIds = user.storesAccess ? user.store_ids : [];

    if (!storeIds.includes(storeId)) {
        storeIds.push(storeId);
    }
    return storeIds.sort();
};

export const signIn = async (username: string, password: string) => {
    const client_id = config.REACT_APP_AUTH_CLIENT_ID;
    const grant_type = 'password';
    const loadUserInfo = 'true';
    const url =
        config.REACT_APP_AUTH_AUTHORITY + '/protocol/openid-connect/token';

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                client_id,
                username,
                password,
                grant_type,
                loadUserInfo
            }).toString()
        });
        const data = await response.json();
        // await SecureStorageService.deleteCredentials();

        if (response.ok) {
            if (
                data.access_token !== undefined &&
                data.refresh_token !== undefined
            ) {
                // const userProfile = await fetchUserProfile(data.access_token);

                return { ...data };

                //   await SecureStorageService.saveCredentials(username, password);
            } else {
                throw Error(
                    'Auth: Login Failed: no access_token/refresh_token present'
                );
            }
        } else {
            // Extract error message from the response body
            const errorMessage = data.error || 'login_failed';
            throw new Error(errorMessage);
        }
    } catch (error) {
        // console.error("Sign-in error:", error);
        throw error; // Re-throw error for the caller to handle
    }
};

export async function signOut(accessToken: string, refreshToken: string) {
    const logoutUrl = config.REACT_APP_LOGOUT_URL;

    const formData = new URLSearchParams();
    formData.append('client_id', config.REACT_APP_AUTH_CLIENT_ID);
    formData.append('refresh_token', refreshToken);

    const response = await fetch(logoutUrl, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData.toString()
    });

    if (!response.ok) {
        // throw new  ('Failed to log out');
        console.log('Failed to log out', response);
    }

    console.log('User logged out successfully');
}

export const checkTokensAndRefreshIfNeeded = async (
    context: AuthContextType
): Promise<
    | {
          access_token: string;
          refresh_token: string;
          expires_in: number;
          userProfile: Record<string, any>;
      }
    | undefined
> => {
    const { session, signOut } = context;

    if (!session?.access_token || !session?.refresh_token) {
        console.log('No tokens found');
        return;
    }

    // Decode the access token to check expiration
    const decodedAccessToken = jwtDecode(session?.access_token);
    const isAccessTokenExpired = decodedAccessToken.exp! * 1000 < Date.now();

    if (isAccessTokenExpired) {
        try {
            // Try to refresh the access token using the refresh token
            const response = await axios.post(
                config.REACT_APP_AUTH_AUTHORITY +
                    '/protocol/openid-connect/token',
                new URLSearchParams({
                    client_id: config.REACT_APP_AUTH_CLIENT_ID, // Replace with your Keycloak client ID
                    grant_type: 'refresh_token',
                    refresh_token: session.refresh_token
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );

            // If refresh was successful, update session with new tokens
            const { access_token, refresh_token } = response.data;
            const decodedAccessToken = jwtDecode(access_token);
            // Update session storage and context with the new tokens
            const userProfile = await fetchUserProfile(access_token);
            context.session = {
                access_token,
                refresh_token,
                expires_in: decodedAccessToken.exp!,
                userProfile
            };

            const resObj = {
                ...response.data,
                userProfile
            };

            return resObj;
            // You can also update localStorage/sessionStorage here if you want
        } catch (error) {
            // If refresh fails, log the user out
            console.error('Token refresh failed', error);
            signOut();
        }
    } else {
        console.log('Access token is still valid');
    }
};
