import { useQuery } from 'react-query';
import { useSession } from '@/contexts';
import { serviceAPI } from '@/utils';
import { useTranslation } from 'react-i18next';

type QueryParams = {
    startDate: string;
    endDate: string;
    storeId: string;
    labelName?: string;
};

export function useTotalSensorsData(params: QueryParams) {
    const { session, user } = useSession();
    const { startDate, endDate, storeId, labelName } = params;
    const { t } = useTranslation();
    if (!session) {
        throw new Error('No session found');
    }

    const accessToken = session?.access_token;
    return useQuery(
        ['totalSensorData', startDate, endDate, storeId, accessToken], // Unique query key
        async () => {
            const response = await serviceAPI('analytic').get(
                `/storeSensors?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
            );

            if (response.status !== 200) {
                throw new Error('Failed to fetch Merchant Data');
            }

            const sensorValues = [
                {
                    name: t('analytics.totalQuantity'),
                    data: [
                        response.data.Bottle_Sensors ?? 0,
                        response.data.Fashion_Sensors ?? 0,
                        response.data.Mini_Safer_Sensors ?? 0,
                        response.data.Safer_Sensors ?? 0,
                        response.data.Spider_Sensors ?? 0
                        // response.data.Other_Sensors ?? 0
                    ]
                }
            ];

            return { sensorValues, ...response };
        },
        {
            enabled:
                !!accessToken &&
                storeId !== undefined &&
                storeId !== null &&
                !!user &&
                !!startDate &&
                !!endDate, // Only run if accessToken is available
            // staleTime: 5 * 60 * 1000, // Optional: data is considered fresh for 5 minutes
            // cacheTime: 10 * 60 * 1000, // Optional: cache the data for 10 minutes
            staleTime: Infinity, // Mark the data as always fresh, no need for automatic refetch
            onError: (err: any) => {
                console.log('Error fetching data', err);
            }
        }
    );
}
