import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { ArrowUpDown, PencilIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import clsx from 'clsx';
import { StoreType, DetacherUnitType } from '@/types';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from '@/components/ui/tooltip';

export const getDetacherUnitColumns = (
    setSelectedRow: (v: string | null, action: 'edit' | 'delete') => void,
    t: (key: string) => string,
    stores?: StoreType[]
): ColumnDef<DetacherUnitType>[] => [
    {
        accessorKey: 'lastOnlineLabel',
        header: ({ column }) => (
            <div className="text-center" style={{ width: '10px' }}>
                {t('detacherUnit.status')}
            </div>
        ),
        cell: ({ row }) => {
            const lastOnlineLabel = row.getValue('lastOnlineLabel');
            const data = row.original;

            return (
                <div className="flex items-center justify-center w-9">
                    {lastOnlineLabel ? (
                        <TooltipProvider delayDuration={100}>
                            <Tooltip>
                                <TooltipTrigger className="flex  items-center justify-start gap-2">
                                    <div className=" h-4 w-4 rounded-full bg-green-400  shadow-green " />
                                </TooltipTrigger>
                                <TooltipContent>
                                    {data.lastOnline as string}
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    ) : (
                        <TooltipProvider delayDuration={100}>
                            <Tooltip>
                                <TooltipTrigger className="flex  items-center justify-start gap-2">
                                    <div className="h-4 w-4 rounded-full bg-red-500" />
                                </TooltipTrigger>
                                <TooltipContent className="bg-red">
                                    {data.lastOnline as string}
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    )}
                </div>
            );
        }
    },
    {
        accessorKey: 'name',
        header: t('detacherUnit.name'),
        cell: ({ row }) => {
            const duName = row.getValue('name');
            const du = row.original;

            return (
                <div className="flex flex-col items-start justify-start  ">
                    {du.description}
                    <span className="text-xs  text-neutral-400">
                        {duName as string}{' '}
                        {du.foreignCheckoutId && `(${du.foreignCheckoutId})`}
                    </span>
                </div>
            );
        },
        filterFn: (row, id, filterValues) => {
            const valueString = [
                row.original.name.toLowerCase(),
                row.original.description.toLowerCase()
            ]
                .filter(Boolean)
                .join(' ');

            // Check if any of the search terms are included in the valueString
            return valueString.indexOf(filterValues.toLowerCase()) > -1;
        }
    },

    {
        accessorKey: 'posType',
        header: t('detacherUnit.type'),
        cell: ({ row }) => {
            const posType = row.getValue('posType');
            return posType || 'N/A';
        }
    },
    {
        accessorKey: 'storeId',
        header: t('detacherUnit.store'),
        cell: ({ row }) => {
            const storeId: number = row.getValue('storeId');
            const store = Array.isArray(stores)
                ? stores?.find((st) => st?.storeId == storeId?.toString())
                : null;
            return !store ? (
                'N/A'
            ) : (
                <div className="flex items-center gap-2">
                    <span>{store.storeName}</span>{' '}
                </div>
            );
        }
    },

    // {
    //     accessorKey: 'rssiLimit',
    //     header: t('detacherUnit.sensitivity'),
    //     cell: ({ row }) => {
    //         const rssi = row.getValue('rssiLimit') as number;
    //         const label =
    //             rssi <= -70
    //                 ? 'high'
    //                 : rssi > -70 && rssi < -50
    //                 ? 'medium'
    //                 : 'low';

    //         return (
    //             <Badge
    //                 className={clsx(
    //                     'capitalize flex flex-row items-center justify-center gap-1 w-24',
    //                     rssi <= -70 && 'bg-blue-600 text-blue-200',
    //                     rssi > -70 && rssi < -50 && 'bg-blue-500 text-blue-200',
    //                     rssi >= -50 && 'bg-blue-200 text-blue-600'
    //                 )}
    //             >
    //                 {t(`detacherUnit.${label}`)} {rssi ? String(rssi) : 'N/A'}
    //             </Badge>
    //         );
    //     }
    // },
    // {
    //     accessorKey: 'buttonActiveSeconds',
    //     header: t('detacherUnit.buttonActiveSeconds'),
    //     cell: ({ row }) => {
    //         const buttonActiveSeconds = row.getValue(
    //             'buttonActiveSeconds'
    //         ) as number;

    //         return (
    //             <Badge
    //                 className={clsx(
    //                     buttonActiveSeconds <= 40 &&
    //                         'bg-blue-100 text-blue-600',
    //                     buttonActiveSeconds <= 50 &&
    //                         'bg-green-100 text-green-700',
    //                     buttonActiveSeconds <= 60 &&
    //                         'bg-yellow-100 text-yellow-700',
    //                     buttonActiveSeconds <= 70 && 'bg-gray-100 text-gray-700'
    //                 )}
    //             >
    //                 {buttonActiveSeconds
    //                     ? String(buttonActiveSeconds) + ' s'
    //                     : 'N/A'}
    //             </Badge>
    //         );
    //     }
    // },

    // Cal asked to remove it
    {
        id: 'actions',
        cell: ({ row }) => {
            const wifi = row.original;

            return (
                <div className=" gap-1">
                    <Button
                        variant="ghost"
                        className="h-8 w-8 p-0"
                        onClick={() => {
                            setSelectedRow(wifi.name, 'edit');
                        }}
                    >
                        <span className="sr-only">Edit</span>
                        <PencilIcon className="h-4 w-4  text-slate-500" />
                    </Button>
                </div>
            );
        }
    }
];
