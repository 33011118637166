import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { SmartTagResponse } from '@/types';
import { useSession } from '@/contexts';
import { useTranslation } from 'react-i18next';
import { serviceAPI } from '@/utils';
import { toast } from 'react-toastify';

type ParamsType = {
    deviceId?: string;
    serialNumber?: string;
    storeId?: string;
    sort?: [{ field: string; sort: 'asc' | 'desc' }];
};

export const useSmartTag = ({
    deviceId,
    serialNumber,
    storeId,
    sort
}: ParamsType) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { session, user } = useSession();
    const [currentPage, setCurrentPage] = useState(0); // Default to page 0
    const [pageSize, setPageSize] = useState(10); // Default to page size of 10

    if (!storeId && !user?.storeAccess) {
        storeId = user?.storeId;
    }

    // Fetch SmartTags
    const {
        data: SmartTags = {
            devices: [],
            totalCount: 0
        },
        isLoading,
        isError
    } = useQuery<SmartTagResponse>(
        [
            'SmartTags',
            storeId,
            pageSize,
            currentPage,
            deviceId,
            serialNumber,
            sort
        ],
        async () => {
            let query = `/battery_levels?numberOnPage=${pageSize}&pageNumber=${currentPage}&store_id=${storeId}`;
            if (!!deviceId) {
                query += `&deviceIds=${deviceId}`;
            } else if (!!serialNumber) {
                query += `&serialNumbers=${serialNumber}`;
            }

            if (sort) {
                // remove old sort
                query = query.replace(/&sort=.*?(&|$)/, '');
                const sortParam = encodeURIComponent(JSON.stringify(sort));

                query += `&sort=${sortParam}`;
            }

            const response = await serviceAPI('device').get(query);

            // console.log('response:', response);

            if (
                response.request.responseURL.startsWith(
                    process.env.CALLBACK_URL as string
                )
            ) {
                // handle the case where the user is not authenticated
                const error = new Error('Unauthorized');
                (error as any).status = 401; // Attach a 401 status
                throw error;
            }
            return response.data;
        },
        {
            enabled: !!session?.access_token && !!user && storeId !== undefined,
            // staleTime: 5 * 60 * 1000,
            staleTime: Infinity,
            onError: (error: any) => {
                console.log('error:', error);
                toast.error(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to fetch Smart Tags'
                );
            }
        }
    );

    // Update SmartTag
    const updateSmartTag = useMutation(
        (updatedSmartTag: {
            deviceId: string;
            storeId: number;
        }): Promise<any> =>
            serviceAPI('device').put(
                `/devices/stores/${updatedSmartTag.storeId}`,
                [updatedSmartTag.deviceId]
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['SmartTags']);
                toast.success(t('smartTag.smartTagUpdateSuccessfully'));
            },
            onError: (error: any) => {
                toast.error(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to update Smart Tag'
                );
            }
        }
    );

    return {
        SmartTags,
        isLoading,
        isError,
        updateSmartTag,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize
    };
};
