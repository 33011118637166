import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { Search, X } from 'lucide-react';

interface PropsType {
    searchField?: string;
    searchPlaceholder?: string;
    searchOptions?: string[];
    setSearch?: (search: { type: string; value: string }) => void;
    search?: { type: string; value: string };
    onFilterApply?: () => void;
    table: any;
    setFiltered?: (v: boolean) => void;
    prefix?: string;
}

function SearchInput({
    searchField,
    searchPlaceholder,
    searchOptions,
    setSearch,
    search,
    onFilterApply,
    table,
    setFiltered,
    prefix
}: PropsType) {
    const { t } = useTranslation();

    return (
        <>
            {!!searchField && !searchOptions?.length && (
                <div className="flex flex-row justify-start items-center rounded-lg  ring-slate-200 ring-1 px-2 max-w-md  h-14 drop-shadow-sm  ">
                    <Input
                        placeholder={searchPlaceholder}
                        value={
                            (table
                                .getColumn(searchField)
                                ?.getFilterValue() as string) ?? ''
                        }
                        onChange={(event) => {
                            setFiltered && setFiltered(true);
                            table
                                .getColumn(searchField)
                                ?.setFilterValue(event.target.value);
                            if (event.target.value === '') {
                                setFiltered && setFiltered(false);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onFilterApply && onFilterApply();
                            }
                        }}
                        className="max-w-sm  h-14 border-none hover:border-none focus:ring-0 focus:ring-transparent focus:outline-none shadow-none"
                    />
                </div>
            )}
            {setSearch && !!searchOptions?.length && (
                <>
                    <div className=" md:hidden flex flex-row justify-start items-center rounded-md border-gray-100 border-2 px-2 w-full lg:col-span-2 h-14">
                        <label className="text-gray-500 w-[90px]">
                            {t('filter.filterBy')}
                        </label>
                        <Select
                            onValueChange={(v) => {
                                setSearch({
                                    type: v as string,
                                    value: search?.value as string
                                });
                            }}
                        >
                            <SelectTrigger className="w-full">
                                <SelectValue
                                    placeholder={t('filter.' + search?.type)}
                                />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    {searchOptions?.map((option) => (
                                        <SelectItem key={option} value={option}>
                                            {t('filter.' + option)}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="flex flex-row justify-start items-center rounded-lg  ring-slate-200 ring-1 px-2   h-14 drop-shadow-sm   ">
                        <div className="hidden md:block">
                            <Select
                                onValueChange={(v) => {
                                    setSearch({
                                        type: v as string,
                                        value: search?.value as string
                                    });
                                }}
                            >
                                <SelectTrigger className="w-auto">
                                    <SelectValue
                                        placeholder={t(
                                            'filter.' + search?.type
                                        )}
                                    />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {searchOptions?.map((option) => (
                                            <SelectItem
                                                key={option}
                                                value={option}
                                            >
                                                {t('filter.' + option)}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        <Input
                            placeholder={searchPlaceholder}
                            prefix={
                                search?.type === 'serialNumber'
                                    ? prefix
                                    : undefined
                            }
                            value={search?.value}
                            onChange={(event) =>
                                setSearch({
                                    type: search?.type as string,
                                    value: event.target.value
                                })
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onFilterApply && onFilterApply();
                                }
                            }}
                            className="max-w-sm border-none hover:border-none focus:ring-0 focus:ring-transparent focus:outline-none shadow-none"
                        />
                        <Button
                            variant="ghost"
                            className="h-8 w-8 p-0 bg-slate-100 px-2"
                            onClick={() => {
                                onFilterApply && onFilterApply();
                            }}
                        >
                            <span className="sr-only">Edit</span>
                            <Search className="h-4 w-4  text-gray-500" />
                        </Button>
                        <Button
                            variant="ghost"
                            className="h-8 w-8 p-0 bg-slate-100 px-2 ml-1"
                            onClick={() => {
                                setSearch({
                                    type: search?.type as string,
                                    value: ''
                                });
                            }}
                        >
                            <span className="sr-only">Edit</span>
                            <X className="h-4 w-4  text-red-500" />
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}

export default SearchInput;
