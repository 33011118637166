import {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    useSidebar
} from '@/components/ui/sidebar';
import { MenuItemType } from './navItemsData';
import clsx from 'clsx';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSession } from '@/contexts';

export function NavMain({ items }: { items: MenuItemType[] }) {
    const location = useLocation();
    const { user } = useSession();
    const { setOpenMobile, isMobile } = useSidebar();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // console.log('User: ', user);

    return (
        <SidebarGroup>
            <SidebarMenu>
                {items.map((item) =>
                    item.isActive ? (
                        <SidebarMenuItem
                            key={item.title}
                            className={clsx(
                                ' flex items-center  py-2 px-2 text-white rounded-lg  ',
                                {
                                    'bg-white/10':
                                        !!item.url &&
                                        location.pathname.endsWith(item.url),
                                    'cursor-not-allowed':
                                        !user?.permissions?.includes(
                                            item.permission
                                        )
                                },
                                {
                                    hidden:
                                        !user?.permissions?.includes(
                                            item.permission
                                        ) && item.permission !== 'cloudOpening'
                                }
                            )}
                        >
                            {item.title === 'feedbackSupport' ? (
                                <Link
                                    to={'https://rapitag.zendesk.com/'}
                                    target="_blank"
                                >
                                    <SidebarMenuButton
                                        tooltip={t(
                                            `dashboardMenu.${item.title}`
                                        )}
                                    >
                                        {item.icon && <item.icon />}
                                        <span className="capitalize">
                                            {t(`dashboardMenu.${item.title}`)}
                                        </span>
                                    </SidebarMenuButton>
                                </Link>
                            ) : (
                                <SidebarMenuButton
                                    onClick={() => {
                                        item.isActive &&
                                            item.url &&
                                            navigate(item.url);
                                        if (isMobile) {
                                            setOpenMobile(false);
                                        }
                                    }}
                                    disabled={
                                        !user?.permissions?.includes(
                                            item.permission
                                        ) && item.permission !== 'cloudOpening'
                                    }
                                    tooltip={t(`dashboardMenu.${item.title}`)}
                                >
                                    {item.icon && <item.icon />}
                                    <span className="capitalize">
                                        {t(`dashboardMenu.${item.title}`)}
                                    </span>
                                </SidebarMenuButton>
                            )}
                        </SidebarMenuItem>
                    ) : null
                )}
            </SidebarMenu>
        </SidebarGroup>
    );
}
