const themes = {
    '0': {
        merchantId: 0,
        name: 'rapitag',
        primary_default: '#115441',
        primary_50: '#EEFBF5',
        primary_200: '#B1E9CF',
        primary_400: '#49BF94',
        primary_500: '#25a47a',
        primary_600: '#178462',
        primary_700: '#126a51',
        primary_800: '#115441',
        background_default: '#EEFBF5'
    },
    '9': {
        merchantId: 9,
        name: 'dm',
        primary_default: '#0c3d91',
        primary_50: '#ecf8ff',
        primary_200: '#b5e2ff',
        primary_400: '#48b6ff',
        primary_500: '#1d94ff',
        primary_600: '#0672ff',
        primary_700: '#005bf6',
        primary_800: '#0749c6'
        // background_default: "#ecf8ff",
        // background_default: "#FFF8E1",
    },
    '5': {
        merchantId: 5,
        name: 'Coles',
        primary_default: '#F73C44',
        primary_50: '#FFF1F2',
        primary_200: '#FFC7C9',
        primary_400: '#fe6b71',
        primary_500: '#F73C44',
        primary_600: '#E01A22',
        primary_700: '#c0151c',
        primary_800: '#9F151B',
        background_default: '#FFF1F2'
    }
};

export default themes;
