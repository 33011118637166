import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';

function CardSkeleton() {
    return (
        <Skeleton className="flex flex-col w-full rounded-xl justify-center space-y-3 bg-slate-100 min-h-[150px]   ">
            <div className="flex flex-col items-center justify-center px-4 mb-3">
                <div className="w-full">
                    <div className="w-20 h-4 bg-white my-2 rounded-md" />
                </div>
                <div className="w-7 h-7 bg-white rounded-full" />
            </div>
            <div className="w-full bg-white h-1  " />
            <div className="flex items-center justify-between px-4">
                <div className="w-20 h-4 bg-white rounded-md" />
                <div className="w-4 h-4 bg-white rounded-md " />
            </div>
        </Skeleton>
    );
}

export default CardSkeleton;
