import { BarChartSkeleton, Chart, Select } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import { dateFormatter } from '@/utils';
import { DateRange } from 'react-day-picker';
import React, { useEffect, useMemo, useState } from 'react';
import { useSensorTypesSelectedCashier } from '@/hooks/data/analytics/checkouts/useSensorTypesSelectedCashier';
import { useDetacherUnit } from '@/hooks';
import { subSeconds } from 'date-fns';

function TotalSensorsUsedOnCashiersHistoricalChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const [cashier, setCashier] = useState('');
    const { DetacherUnits: checkouts } = useDetacherUnit({
        storeId: storeId!
    });

    const { data, error, isLoading } = useSensorTypesSelectedCashier({
        startDate: dateFormatter(dateRange?.from),
        endDate: dateRange?.to
            ? dateFormatter(subSeconds(dateRange.to, 1))
            : '',
        storeId: storeId!,
        cashier: cashier
    });

    // create a new array of objects with unique label and value properties
    const CASHIER_ITEMS = useMemo(() => {
        const seen = new Set();
        return checkouts
            .map((checkout) => ({
                label: checkout.description,
                value: checkout.description
            }))
            .filter((item) => {
                if (seen.has(item.value)) {
                    return false;
                }
                seen.add(item.value);
                return true;
            });
    }, [checkouts]);

    useEffect(() => {
        if (checkouts.length > 0 && CASHIER_ITEMS.length > 0) {
            // console.log('checkouts[0]?.des', checkouts[0]?.name);

            setCashier(checkouts[0]?.description);
        }
    }, [checkouts.length, CASHIER_ITEMS]);

    if (
        isLoading ||
        !storeId ||
        !cashier ||
        !data?.intervals ||
        !data?.smartTagsHistory
    )
        return (
            <div className="mt-6">
                <BarChartSkeleton />
            </div>
        );

    return (
        <div className="relative pt-2 mt-6  ">
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2 mb-3 md:absolute px-3 top-[-15px] left-1  w-full">
                <Select
                    items={CASHIER_ITEMS}
                    onChange={setCashier}
                    value={cashier}
                />
            </div>
            <Chart
                type={'line'}
                data={data?.smartTagsHistory || []}
                options={getChartOptions(data?.intervals || [], 'line')}
            />
        </div>
    );
}

export default TotalSensorsUsedOnCashiersHistoricalChart;
