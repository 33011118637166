import { lazy, Suspense } from 'react';
import './App.css';
import { ReactQueryProvider, SessionProvider, StoreProvider } from '@/contexts';
import { Routes, Route } from 'react-router-dom';
import { Layout, ProtectedRoute } from '@/components';
import { useLocalStorage } from './hooks';
import setTheme from './utils/setTheme';
import themes from './theme';
import { ThemeType } from './types';
import { ToastContainer, Slide } from 'react-toastify';

// Auth pages
const Login = lazy(() => import('@/pages/auth/Login'));
const ResetPassword = lazy(() => import('@/pages/auth/ResetPassword'));
const CheckInbox = lazy(() => import('@/pages/auth/CheckInbox'));

// Dashboard pages
const Analytics = lazy(() => import('@/pages/dashboard/Analytics'));
const Stores = lazy(() => import('@/pages/dashboard/Stores'));
const Wifi = lazy(() => import('@/pages/dashboard/Wifi'));
const DetacherUnits = lazy(() => import('@/pages/dashboard/DetacherUnits'));
const SmartTags = lazy(() => import('@/pages/dashboard/SmartTags'));
const Users = lazy(() => import('@/pages/dashboard/Users'));
const AdminTools = lazy(() => import('@/pages/dashboard/AdminTools'));

// Error pages
const NotFound = lazy(() => import('@/pages/errors/404'));
const Forbidden = lazy(() => import('@/pages/errors/403'));

function App() {
    const [merchant_id] = useLocalStorage('merchant_id', '');

    const merchantId = ['0', '5', '9'].includes(merchant_id)
        ? merchant_id
        : '0';

    setTheme(themes[merchantId as keyof typeof themes] as ThemeType);

    return (
        <div className="bg-background">
            <SessionProvider>
                <Suspense
                    fallback={
                        <div className="flex justify-center items-center w-full h-full"></div>
                    }
                >
                    <ReactQueryProvider>
                        <StoreProvider>
                            <Routes>
                                <Route path="/" element={<Login />} />
                                <Route
                                    path="/reset-password"
                                    element={<ResetPassword />}
                                />
                                <Route
                                    path="/check-inbox"
                                    element={<CheckInbox />}
                                />
                                <Route element={<ProtectedRoute />}>
                                    <Route path="/" element={<Layout />}>
                                        <Route
                                            path="/analytics"
                                            element={<Analytics />}
                                        />
                                    </Route>
                                    <Route path="/" element={<Layout />}>
                                        <Route
                                            path="/stores"
                                            element={<Stores />}
                                        />
                                    </Route>
                                    <Route path="/" element={<Layout />}>
                                        <Route
                                            path="/smart-tags"
                                            element={<SmartTags />}
                                        />
                                    </Route>
                                    <Route path="/" element={<Layout />}>
                                        <Route
                                            path="/detacher-units"
                                            element={<DetacherUnits />}
                                        />
                                    </Route>
                                    <Route path="/" element={<Layout />}>
                                        <Route
                                            path="/users"
                                            element={<Users />}
                                        />
                                    </Route>
                                    <Route path="/" element={<Layout />}>
                                        <Route
                                            path="/admin-tools"
                                            element={<AdminTools />}
                                        />
                                    </Route>
                                    <Route path="/" element={<Layout />}>
                                        <Route
                                            path="/wifi-configs"
                                            element={<Wifi />}
                                        />
                                    </Route>
                                </Route>
                                <Route path="/403" element={<Forbidden />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                            <ToastContainer
                                position="bottom-left"
                                autoClose={4000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                transition={Slide}
                            />
                        </StoreProvider>
                    </ReactQueryProvider>
                </Suspense>
            </SessionProvider>
        </div>
    );
}

export default App;
