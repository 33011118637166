import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { PencilIcon } from 'lucide-react';
import { StoreType, SmartTagItemType } from '@/types';
import { format, parseISO } from 'date-fns';
import SortHeader from '../common/SortableHeader';
import BatteryStatus from './BatteryStatus';

export const getSmartTagColumns = (
    setSelectedRow: (v: string | null, action: 'edit' | 'delete') => void,
    t: (key: string) => string,
    stores?: StoreType[]
): ColumnDef<SmartTagItemType>[] => [
    {
        accessorKey: 'serialNumber',
        header: t('smartTag.serialNumber'),
        cell: ({ row }) => {
            const item = row.original;

            return (
                <div className="flex flex-col items-start justify-start  ">
                    {item.serialNumber ?? 'N/A'}
                    <span className="text-xs  text-neutral-400">
                        {t('smartTag.tagId')}: {item.deviceName}
                    </span>
                </div>
            );
        }
    },
    {
        accessorKey: 'deviceType',
        header: ({ column }) => {
            return <SortHeader column={column} title={t('smartTag.tagType')} />;
        },
        cell: ({ row }) => {
            const deviceType: string = row.getValue('deviceType');

            return !deviceType ? (
                'N/A'
            ) : (
                <div className="flex items-center gap-2">
                    <span className=" capitalize">{deviceType}</span>
                </div>
            );
        }
    },

    {
        accessorKey: 'batteryLevel',
        header: ({ column }) => {
            return (
                <SortHeader
                    column={column}
                    title={t('smartTag.batteryLevel')}
                />
            );
        },

        cell: ({ row }) => {
            const batteryLevel = row.getValue('batteryLevel') as number;
            const type = row.getValue('deviceType') as string;
            return <BatteryStatus batteryLevel={batteryLevel} type={type} />;
        }
    },

    {
        accessorKey: 'storeId',
        header: t('detacherUnit.store'),
        cell: ({ row }) => {
            const storeId: number = row.getValue('storeId');
            const store = Array.isArray(stores)
                ? stores?.find((st) => st.storeId == storeId?.toString())
                : null;
            return !store ? (
                'N/A'
            ) : (
                <div className="flex items-center gap-2">
                    <span>{store.storeName}</span>
                </div>
            );
        }
    },

    {
        accessorKey: 'timestampCreated',
        header: ({ column }) => {
            return (
                <SortHeader
                    column={column}
                    title={t('smartTag.lastReported')}
                />
            );
        },
        cell: ({ row }) => {
            const dateString = row.getValue('timestampCreated') as string;

            if (!dateString) {
                return (
                    <span className="text-[13px] text-neutral-400">N/A</span>
                );
            }

            const date = parseISO(dateString);
            const formattedDate = format(date, 'MMM do, yyyy HH:mm:ss');

            return (
                <span className="text-[13px] text-neutral-600">
                    {formattedDate}
                </span>
            );
        }
    },

    {
        id: 'actions',
        cell: ({ row }) => {
            const device = row.original;

            return (
                <div className=" gap-1">
                    <Button
                        variant="ghost"
                        disabled={stores?.length === 1}
                        className="h-8 w-8 p-0"
                        onClick={() => {
                            setSelectedRow(device.sensorId, 'edit');
                        }}
                    >
                        <span className="sr-only">Edit</span>
                        <PencilIcon className="h-4 w-4  text-slate-500" />
                    </Button>
                </div>
            );
        }
    }
];
