import { Button } from '@/components/ui/button';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { useEffect, useState } from 'react';
import { useCloudOpening } from '@/hooks';
import { FloatingLabelInput } from '../../ui/floatingLabelInput';
import { Label } from '@/components/ui/label';
import clsx from 'clsx';

const formSchema = z.object({
    jobId: z.string()
});

function JobStatusForm({ jobId }: { jobId: string }) {
    const { t } = useTranslation();
    const [newJobID, setJobId] = useState<string | undefined>(jobId);
    const { JobStatus, removeJobStatus, refetchJobStatus } = useCloudOpening({
        jobId: newJobID
    });
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            jobId: jobId
        }
    });

    useEffect(() => {
        if (jobId) {
            form.reset();
            setJobId('');
            removeJobStatus();
            form.setValue('jobId', jobId);
        }
    }, [jobId, form]);

    function onSubmit(values: z.infer<typeof formSchema>) {
        setJobId(values.jobId);
        if (JobStatus) {
            refetchJobStatus();
        }
    }

    return (
        <div className="flex flex-col space-y-6">
            <h2 className="text-xl font-semibold text-primary-500">
                {t('cloudOpening.jobStatus')}
            </h2>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="flex flex-col space-y-3"
                >
                    <FormField
                        control={form.control}
                        name="jobId"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="jobId"
                                    label={t('cloudOpening.jobId') + ' *'}
                                    type="text"
                                    required
                                    className="bg-white"
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button
                        disabled={!form.watch('jobId')}
                        type="submit"
                        className="w-full md:w-1/3"
                    >
                        {t('cloudOpening.getJobStatus')}
                    </Button>
                </form>
            </Form>
            <div className=" relative flex flex-col space-y-3 p-3   rounded-lg border border-gray-300">
                <Label className=" absolute left-3 -top-2 bg-white  px-1 text-gray-500 font-normal">
                    {t('cloudOpening.result')}
                </Label>
                <span className="text-sm  text-slate-500  ">
                    {t('cloudOpening.jobStatus')}
                    {'    '}
                    <span
                        className={clsx(
                            ' text-[14px] font-medium  ml-2 px-2 py-1 rounded-md  ',
                            {
                                'bg-red-100 text-red-500':
                                    JobStatus?.status === 'FAILED',
                                'bg-yellow-200 text-yellow-600':
                                    JobStatus?.status === 'OPENING',
                                'bg-green-200 text-green-600':
                                    JobStatus?.status === 'DONE'
                            }
                        )}
                    >
                        {JobStatus?.status ?? '-'}
                    </span>
                </span>
                <span className="text-sm  text-slate-500  ">
                    {t('cloudOpening.orderId')}
                    {'    '}
                    <span className="  font-normal text-[16px]  text-slate-700 ml-2 ">
                        {JobStatus?.order_id ?? '-'}{' '}
                    </span>
                </span>

                <span className="text-sm  text-slate-500  ">
                    {t('cloudOpening.timeNeeded')}
                    {'    '}
                    <span className="  font-normal text-[16px]  text-slate-700 ml-2 ">
                        {JobStatus?.time_needed ?? '-'}{' '}
                    </span>
                </span>
                <div className="flex flex-col space-y-2">
                    <span className="text-sm  text-slate-500  ">
                        {t('cloudOpening.deviceList')}
                    </span>
                    <div className="flex flex-wrap gap-3">
                        {JobStatus?.device_list?.map((device, index) => (
                            <div
                                key={index}
                                className={
                                    'p-1 rounded-lg border text-xs w-auto text-gray-500 border-gray-300 bg-gray-100'
                                }
                            >
                                {device}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col space-y-2">
                    <span className="text-sm  text-slate-500  ">
                        {t('cloudOpening.successList')}
                    </span>
                    <div className="flex flex-wrap gap-3">
                        {JobStatus?.list_success?.map((device, index) => (
                            <div
                                key={index}
                                className={
                                    'p-1 rounded-lg border text-xs w-auto text-green-500 border-green-300 bg-green-50'
                                }
                            >
                                {device}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col space-y-2">
                    <span className="text-sm  text-slate-500  ">
                        {t('cloudOpening.failedList')}
                    </span>
                    <div className="flex flex-wrap gap-3">
                        {JobStatus?.list_failed?.map((device, index) => (
                            <div
                                key={index}
                                className={
                                    'p-1 rounded-lg border text-xs w-auto text-red-500 border-red-300 bg-red-50'
                                }
                            >
                                {device}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JobStatusForm;
