import { ApexOptions } from 'apexcharts';

const getChartOptions = (
    xaxis: string[],
    type: 'line' | 'bar' | 'pie' | 'donut' | 'area' | 'radar',
    horizontal?: boolean,
    group?: boolean
): ApexOptions => {
    const options: ApexOptions & {
        stroke?: {
            show?: boolean;
            width?: number;
            colors?: string[];
            curve?: string;
        };
    } = {
        //   colors: group ? [] : ["#FFC7C9", "#C1DFF6", "#FCE38B", "#E4EAE8", "#F7D7F2"],
        labels: xaxis,

        series: [
            {
                name: 'My Custom Label',
                data: []
            }
        ],
        chart: {
            type: type,
            id: 'apexchart-example',
            zoom: {
                enabled: false
            }
        },
        xaxis: {
            // categories: xaxis
            categories: xaxis.map((label) => {
                if (label.length > 12) {
                    const match = label.match(
                        /(\d{4}-\d{2}-\d{2}) \(([^)]+)\)/
                    );
                    if (match) {
                        const formattedLabel = [
                            match[1],
                            match[2].slice(0, 3).toUpperCase()
                        ];
                        return formattedLabel;
                    } else {
                        return label;
                    }
                }
                return label;
            })
        },
        legend: {
            position: 'bottom',
            offsetY: 0
        },
        plotOptions: {
            bar: {
                borderRadius: group ? 3 : 5,
                distributed: !group,
                dataLabels: {
                    position: 'top'
                },
                horizontal: horizontal || false,
                columnWidth: group ? '100%' : '70%'
            },

            pie: {
                customScale: 0.8
            }
        },
        dataLabels: {
            formatter: function (val) {
                return val === 0 ? '' : val.toString(); // Hide if value is 0
            },
            offsetX: !group ? -20 : 0,
            textAnchor: 'middle',
            style: {
                fontSize: '13px',
                colors: ['#565656'],
                fontWeight: 'bold'
            },
            dropShadow: {
                enabled: false
            }
        },
        states: {
            hover: {
                filter: {
                    type: 'darken' as string,
                    value: 0.6
                } as {
                    type: string;
                    value: number;
                }
            }
        },
        fill: {
            opacity: 1,
            colors: ['#FFC7C9', '#C1DFF6', '#FCE38B', '#E4EAE8', '#F7D7F2']
        }
    };

    if (type === 'line') {
        options['stroke'] = {
            curve: 'smooth'
        };
    } else {
        options['stroke'] = {
            show: true,
            width: 1,
            colors: ['transparent'],
            curve: 'smooth'
        };
    }
    return options;
};

export default getChartOptions;
