import { BatteryFull, BatteryLow, BatteryMedium } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import clsx from 'clsx';

const BatteryStatus = ({
    batteryLevel,
    type
}: {
    batteryLevel: number;
    type: string;
}) => {
    let batteryPercentage = 0;
    let Icon = BatteryLow;
    let badgeClass = 'bg-gray-200 text-gray-600';
    const isFashionTag = !!type ? type.startsWith('fashion') : null;
    const maxValue = isFashionTag ? 4.1 : 4.15;
    const minValue = isFashionTag ? 3.24 : 3.34;
    const range = isFashionTag ? 0.86 : 0.81;
    const batteryLevelRange = maxValue - batteryLevel;

    if (batteryLevel < minValue) {
        batteryPercentage = 0;
    } else {
        batteryPercentage = ((range - batteryLevelRange) / range) * 100;
        batteryPercentage = Math.min(batteryPercentage, 100);
    }

    if (isFashionTag && !!type && batteryLevel !== null) {
        if (batteryLevel >= 3.58) {
            Icon = BatteryFull;
            badgeClass = 'bg-blue-200 text-blue-600';
        } else if (batteryLevel >= 3.45 && batteryLevel < 3.58) {
            Icon = BatteryMedium;
            badgeClass = 'bg-orange-200 text-orange-600';
        } else if (batteryLevel < 3.45) {
            Icon = BatteryLow;
            badgeClass = 'bg-red-200 text-red-600';
        }
    } else if (!!type && batteryLevel !== null) {
        if (batteryLevel > 3.7) {
            Icon = BatteryFull;
            badgeClass = 'bg-blue-200 text-blue-600';
        } else if (batteryLevel >= 3.5 && batteryLevel <= 3.7) {
            Icon = BatteryMedium;
            badgeClass = 'bg-orange-200 text-orange-600';
        } else if (batteryLevel < 3.5) {
            Icon = BatteryLow;
            badgeClass = 'bg-red-200 text-red-600';
        }
    }

    return (
        <Badge
            className={clsx(
                'capitalize flex flex-row items-center justify-center gap-1 w-24',
                badgeClass
            )}
        >
            {batteryLevel !== null && !!type && <Icon className="h-5 w-6" />}
            {batteryLevel !== null && !!type
                ? `${batteryPercentage.toFixed(0)} %`
                : 'N/A'}
        </Badge>
    );
};

export default BatteryStatus;
