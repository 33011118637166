import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { ArrowUpDown, PencilIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import clsx from 'clsx';
import { StoreType } from '@/types';

export const getStoreColumns = (
    setSelectedRow: (v: string | null) => void,
    t: (key: string) => string
): ColumnDef<StoreType>[] => [
    {
        accessorKey: 'storeName',
        header: t('stores.storeName'),
        cell: ({ row }) => {
            const storeName = row.getValue('storeName');
            const store = row.original;

            return (
                <div className="flex flex-col items-start justify-start">
                    <p className="text-xs text-neutral-400">
                        ID: {store?.storeId}{' '}
                        {store?.foreignStoreId && `(${store?.foreignStoreId})`}
                    </p>
                    {storeName as string}
                </div>
            );
        }
    },
    {
        accessorKey: 'city',
        header: t('stores.city')
    },

    {
        accessorKey: 'storeAddress',
        header: t('stores.address'),
        cell: ({ row }) => {
            const storeAddress = row.getValue('storeAddress');
            const store = row.original;

            return (
                <div className="flex flex-col items-start justify-start">
                    {storeAddress as string}, {store.postcode as string}
                </div>
            );
        }
    },
    {
        accessorKey: 'manager',
        header: t('stores.manager')
    },
    // {
    //     accessorKey: 'EASGateType',
    //     header: t('stores.EASGateType'),
    //     cell: ({ row }) => {
    //         const easGateType = row.getValue('EASGateType');

    //         return (
    //             <Badge
    //                 className={clsx(
    //                     'capitalize',
    //                     easGateType === 'AM' && 'bg-blue-100 text-blue-600',
    //                     easGateType === 'RF' && 'bg-green-100 text-green-700'
    //                 )}
    //             >
    //                 {easGateType as string}
    //             </Badge>
    //         );
    //     }
    // },
    // {
    //     accessorKey: 'connectionType',
    //     header: t('stores.connectionType'),
    //     cell: ({ row }) => {
    //         const connectionType = row.getValue('connectionType');

    //         return (
    //             <Badge
    //                 className={clsx(
    //                     'capitalize',
    //                     connectionType === 'Ethernet' &&
    //                         'bg-green-100 text-green-700',
    //                     connectionType === 'WiFi' && 'bg-blue-100 text-blue-600'
    //                 )}
    //             >
    //                 {connectionType as string}
    //             </Badge>
    //         );
    //     }
    // },
    // {
    //     accessorKey: 'numberOfRegisters',
    //     header: ({ column }) => (
    //         <div className="text-center">
    //             <Button
    //                 variant="ghost"
    //                 onClick={() =>
    //                     column.toggleSorting(column.getIsSorted() === 'asc')
    //                 }
    //             >
    //                 {t('stores.registers')}
    //                 <ArrowUpDown className="h-4 w-4" />
    //             </Button>
    //         </div>
    //     ),
    //     cell: ({ row }) => {
    //         const value = row.getValue('numberOfRegisters');

    //         return (
    //             <div className="flex flex-col items-center justify-center">
    //                 {value as string}
    //             </div>
    //         );
    //     }
    // },
    // {
    //     accessorKey: 'numberOfUsers',
    //     header: ({ column }) => (
    //         <div className="text-center">
    //             <Button
    //                 variant="ghost"
    //                 onClick={() =>
    //                     column.toggleSorting(column.getIsSorted() === 'asc')
    //                 }
    //             >
    //                 {t('stores.users')}
    //                 <ArrowUpDown className="h-4 w-4" />
    //             </Button>
    //         </div>
    //     ),
    //     cell: ({ row }) => {
    //         const value = row.getValue('numberOfUsers');

    //         return (
    //             <div className="flex flex-col items-center justify-center">
    //                 {value as string}
    //             </div>
    //         );
    //     }
    // },
    // {
    //     accessorKey: 'status',
    //     header: t('stores.status'),
    //     cell: ({ row }) => {
    //         const status = row.getValue('status');

    //         return (
    //             <Badge
    //                 className={clsx(
    //                     'capitalize',
    //                     status === 'Active' && 'bg-green-100 text-green-700',
    //                     status === 'Inactive' && 'bg-orange-100 text-orange-600'
    //                 )}
    //             >
    //                 {t(`stores.${status}`)}
    //             </Badge>
    //         );
    //     }
    // },
    {
        id: 'actions',
        cell: ({ row }) => {
            const store = row.original;

            return (
                <Button
                    variant="ghost"
                    className="h-8 w-8 p-0"
                    onClick={() => {
                        setSelectedRow(store?.storeId);
                    }}
                >
                    <span className="sr-only">Edit</span>
                    <PencilIcon className="h-4 w-4 text-slate-500" />
                </Button>
            );
        }
    }
];
