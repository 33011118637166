import { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import {
    AuthContextType,
    AccessToken,
    AuthContextStoreType,
    UserType
} from '@/types';
import {
    checkTokensAndRefreshIfNeeded,
    fetchUserProfile,
    signIn,
    signOut
} from './authHandlers';
import { useLocalStorage, useSessionStorage } from '@/hooks';
import { useNavigate } from 'react-router-dom';

const defaultAuthContext: AuthContextType = {
    signIn: () => undefined,
    signOut: () => undefined,
    user: undefined,
    session: undefined,
    loading: true
};

const defaultAuthContextStore: AuthContextStoreType = undefined;

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

export const SessionProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const [session, setSession] = useState<AuthContextStoreType>(
        defaultAuthContextStore
    );
    const [localStorageAccessToken, setAccessToken] = useLocalStorage(
        'access_token',
        ''
    );
    const [, setRefreshToken] = useLocalStorage('refresh_token', '');
    const [, setRememberMe] = useLocalStorage('remember_me', '');
    const [, setMerchantId] = useLocalStorage('merchant_id', '');
    const [user, setUser] = useState<UserType | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [session_access_token, setSessionAccessToken] = useSessionStorage(
        'access_token',
        ''
    );
    const [session_refresh_token, setSessionRefreshToken] = useSessionStorage(
        'refresh_token',
        ''
    );

    // Update user whenever session changes
    useEffect(() => {
        if (session?.access_token && session?.userProfile) {
            setUser(session.userProfile);
        }
    }, [session]);

    // Check session storage or local storage for access token on initial load
    useEffect(() => {
        setLoading(true);
        const storedAccessToken =
            session_access_token || localStorageAccessToken;
        if (storedAccessToken) {
            const decoded = jwtDecode(storedAccessToken) as AccessToken;
            const isTokenValid = decoded.exp! * 1000 > Date.now();
            if (isTokenValid) {
                fetchUserProfile(storedAccessToken).then((userProfile) => {
                    setSession({
                        access_token: storedAccessToken,
                        refresh_token: session_refresh_token,
                        expires_in: jwtDecode(storedAccessToken).exp!,
                        userProfile
                    });
                    setMerchantId(userProfile.merchantId);
                    setUser(userProfile); // Set user if token is present
                });
            }
        }
        setLoading(false);
    }, [session_access_token, localStorageAccessToken]);

    return (
        <AuthContext.Provider
            value={{
                signIn: async (
                    email: string,
                    password: string,
                    rememberMe: boolean
                ) => {
                    const data = await signIn(email, password);
                    setLoading(true);
                    if (rememberMe) {
                        setAccessToken(data.access_token);
                        setRefreshToken(data.refresh_token);
                        setRememberMe(JSON.stringify(true));
                    } else {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        localStorage.removeItem('remember_me');
                    }

                    setSessionAccessToken(data.access_token);
                    setSessionRefreshToken(data.refresh_token);

                    // fetchUserProfile(data.access_token).then((userProfile) => {
                    //     setSession({
                    //         access_token: data.access_token,
                    //         refresh_token: session_refresh_token,
                    //         expires_in: jwtDecode(data.access_token).exp!,
                    //         userProfile
                    //     });
                    //     setUser(userProfile); // Set user if token is present
                    //     setMerchantId(userProfile.merchantId);
                    //     setLoading(false);
                    // });
                    // .catch(() => {
                    //     navigate('/');
                    // });
                },
                signOut: async () => {
                    await signOut(session_access_token, session_refresh_token);
                    setSession(defaultAuthContextStore);
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('remember_me');
                    sessionStorage.removeItem('access_token');
                    sessionStorage.removeItem('refresh_token');
                    setUser(undefined); // Clear user on sign out
                },
                user,
                loading,
                session // Provide session through the context
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useSession = (): AuthContextType & { loading: boolean } => {
    const context = useContext(AuthContext);
    const [local_storage_access_token, setLocalStorageAccessToken] =
        useLocalStorage('access_token', '');
    const [local_storage_arefresh_token, setLocalStorageRefreshToken] =
        useLocalStorage('refresh_token', '');
    const [rememberMe, setRememberMe] = useLocalStorage('remember_me', '');

    const [session_storage_access_token, setSessionAccessToken] =
        useSessionStorage('access_token', '');
    const [session_storage_refresh_token, setSessionRefreshToken] =
        useSessionStorage('refresh_token', '');
    const [loading, setLoading] = useState(true);

    const currentAccessToken: string = session_storage_access_token
        ? session_storage_access_token
        : local_storage_access_token;
    const currentRefreshToken: string = session_storage_refresh_token
        ? session_storage_refresh_token
        : local_storage_arefresh_token;

    //
    useEffect(() => {
        if (currentAccessToken && currentRefreshToken) {
            const decoded = jwtDecode(currentAccessToken) as AccessToken;

            if (decoded.exp) {
                const isTokenExpired = decoded.exp * 1000 < Date.now();
                if (isTokenExpired && context.session?.refresh_token) {
                    refreshToken();
                }

                context.user = context.session?.userProfile;
                context.session = {
                    access_token: currentAccessToken,
                    refresh_token: currentRefreshToken,
                    expires_in: decoded.exp,
                    userProfile: context.session?.userProfile
                };
            }
        }
        setLoading(false);
    }, [currentAccessToken, currentRefreshToken, context.session?.userProfile]);

    const refreshToken = async () => {
        const response = await checkTokensAndRefreshIfNeeded(context);

        if (response) {
            const { access_token, refresh_token, userProfile } = response;
            context.user = userProfile;
            context.session = {
                access_token,
                refresh_token,
                expires_in: jwtDecode(access_token).exp!,
                userProfile
            };
            if (rememberMe) {
                setLocalStorageAccessToken(access_token);
                setLocalStorageRefreshToken(refresh_token);
            }
            setSessionAccessToken(access_token);
            setSessionRefreshToken(refresh_token);
        }
    };

    return { ...context, loading };
};
