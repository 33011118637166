import { useQuery } from 'react-query';
import { useState } from 'react';
import { useSession } from '@/contexts';
import { serviceAPI } from '@/utils';

type QueryParams = {
    startDate: string;
    endDate: string;
    storeId: string | undefined;
};

export const useReport = (params: QueryParams) => {
    const [errorMessage] = useState<string | null>(null);
    const { user } = useSession();
    const { startDate, endDate, storeId } = params;

    // Fetch Foreign Checkout Names
    const {
        data: LogReports,
        isLoading,
        isError,
        refetch
    } = useQuery(
        ['LogReports'],
        async () => {
            let query = `/openJobLogs?merchantId=${user?.merchantId}&startDate=${startDate}&endDate=${endDate}`;
            if (storeId) {
                query = `/openJobLogs?merchantId=${user?.merchantId}&startDate=${startDate}&endDate=${endDate}&storeId=${storeId}`;
            }

            const response = await serviceAPI('payment').get(query);
            if (
                response.request.responseURL.startsWith(
                    process.env.CALLBACK_URL as string
                )
            ) {
                // handle the case where the user is not authenticated
                const error = new Error('Unauthorized');
                (error as any).status = 401; // Attach a 401 status
                throw error;
            }

            return response.data;
        },
        {
            enabled: false,
            // staleTime: 5 * 60 * 1000,
            staleTime: Infinity,
            onError: (error: any) => {
                console.log('error:', error);
            }
        }
    );

    return {
        LogReports,
        isLoading,
        isError,
        errorMessage,
        refetch
    };
};
