import { CustomDatePicker, DataTable } from '@/components';
import { useTranslation } from 'react-i18next';

import { useReport } from '@/hooks';
import { useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { subDays, startOfDay, addDays } from 'date-fns';
import { useStoreContext } from '@/contexts';
import { ComboBoxResponsive } from '@/components/ui/combobox';
import { Button } from '@/components/ui/button';
import { dateFormatter } from '@/utils';
import { subSeconds } from 'date-fns';
import { getLogReportColumns } from './TableColumns';

const initialDateRange: DateRange = {
    from: subDays(startOfDay(new Date()), 30),
    to: new Date()
};

function ReportsTab() {
    const { t } = useTranslation();
    const [date, setDate] = useState<DateRange | undefined>(initialDateRange);

    const [parsedData, setParsedData] = useState<{ [key: string]: string }[]>(
        []
    );

    const { stores, isLoading: fetchStoresLoading } = useStoreContext();
    const [store, setStore] = useState<string | undefined>();
    const { LogReports, isError, isLoading, refetch } = useReport({
        startDate: dateFormatter(date?.from),
        endDate: date?.to
            ? dateFormatter(subSeconds(addDays(date.to as Date, 1), 1))
            : '',
        storeId: store!
    });

    const [currentPage, setCurrentPage] = useState(0); // Default to page 0
    const [pageSize, setPageSize] = useState(10); // Default to page size of 10

    // convert LogReports to array of objects
    useEffect(() => {
        if (LogReports && !isLoading) {
            const createJson = () => {
                setParsedData([]);

                if (typeof LogReports === 'string') {
                    const rows = LogReports.split('\n').map((row) =>
                        row.split(';')
                    ); // Use ";" as delimiter
                    if (rows.length < 2) return; // Ensure data is valid

                    const headers = rows[0].map((header) => header.trim()); // Extract headers
                    const data = rows
                        .slice(1)
                        .map((row) =>
                            Object.fromEntries(
                                row.map((value, index) => [
                                    headers[index],
                                    value.trim()
                                ])
                            )
                        );

                    setParsedData(data);
                }
            };

            createJson();
        }
    }, [LogReports, isLoading]);

    const downloadCSV = async () => {
        try {
            // Step 2: Create a Blob
            const blob = new Blob([LogReports], { type: 'text/csv' });

            // Step 3: Create a download link and trigger download
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'log-report.csv'; // File name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error fetching CSV:', error);
        }
    };

    // Transform stores into dropdown options using useMemo for optimization
    const transformedData = useMemo(() => {
        let storeOptions = [];
        if (stores?.length === 1) {
            storeOptions = [
                {
                    value: stores[0].storeId?.toString(),
                    label: stores[0].storeName
                }
            ];
        } else {
            storeOptions = stores.map((store) => ({
                value: store?.storeId?.toString(),
                label: store?.storeName
            }));
        }
        storeOptions.unshift({ value: '', label: t('common.allStores') });
        return storeOptions;
    }, [stores]);

    // Automatically select the first store when data loads
    useEffect(() => {
        if (transformedData.length > 0) {
            setStore(transformedData[0].value);
        }
    }, [transformedData]);

    return (
        <div className="flex flex-col  items-center h-full pt-4  bg-white ">
            <div className="grid sm:grid-cols-1 lg:grid-cols-8 gap-3  p-4 rounded-lg w-full ">
                {transformedData.length > 1 && (
                    <div className="col-span-2 ">
                        <ComboBoxResponsive
                            onChange={(storeId) => setStore(storeId!)}
                            statuses={transformedData}
                            showPrefixIcon
                            className="flex h-14 w-full border-gray-300 focus:border-primary-500	
                            focus:ring-primary-500 rounded-lg   
                            bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                            file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                            placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                            focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm hover:bg-white hover:border-gray-400"
                            value={store?.toString()}
                            placeholder={t('common.selectStore')}
                        />
                    </div>
                )}
                <div className=" w-full col-span-2">
                    <CustomDatePicker
                        dateRange={date}
                        setDateRange={setDate}
                        className="border-gray-300 focus:border-primary-500"
                    />
                </div>

                <Button
                    onClick={() => refetch()}
                    className="w-full md:w-1/4 min-w-[130px] col-start-1"
                    disabled={fetchStoresLoading || isLoading}
                >
                    {isLoading
                        ? t('common.loading')
                        : t('reports.createReport')}
                </Button>

                <Button
                    onClick={downloadCSV}
                    className="w-full md:w-1/4 min-w-[100px] col-start-2"
                    variant={'secondary'}
                    disabled={isLoading || !LogReports}
                >
                    {t('reports.downloadReport')}
                </Button>

                {/* display an error message if there is an error */}
                {isError && (
                    <div className="text-red-500 text-sm text-center  col-start-1 col-span-4 mt-3">
                        {t('error.somethingWentWrong')}
                    </div>
                )}
            </div>
            {/* {LogReports && (
                <div className="bg-white p-4 rounded-lg w-full md:w-1/2 mt-3">
                    <div className="flex items-center mb-4 border border-gray-300 rounded-lg px-3 py-2 w-full">
                        <Input
                            type="text"
                            placeholder={t('common.search')}
                            value={searchTerm}
                            className="  h-8 border-none hover:border-none focus:ring-0 focus:ring-transparent focus:outline-none shadow-none"
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                        />
                        <button
                            className="ml-2 text-gray-500 hover:text-gray-700"
                            onClick={() => setSearchTerm('')}
                        >
                            ✕
                        </button>
                    </div>
                    <div className=" overflow-x-scroll overflow-y-scroll mt-4 max-h-[500px]">
                        <JsonView
                            keyName="data"
                            value={filteredParsedData}
                            collapsed={false}
                            //   style={lightTheme}
                        />
                    </div>
                </div>
            )} */}

            {parsedData.length > 0 && (
                <div className="w-full overflow-x-auto px-4">
                    <DataTable
                        columns={getLogReportColumns(t)}
                        data={parsedData || []}
                        searchField="job_id"
                        searchPlaceholder={t('reports.searchByJobId')}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            )}
        </div>
    );
}

export default ReportsTab;
