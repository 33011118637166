import { Button } from '@/components/ui/button';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { FloatingLabelInput } from '@/components/ui/floatingLabelInput';
import { ComboBoxResponsive } from '../ui/combobox';
import { useEffect, useMemo, useState } from 'react';
import { useStoreContext } from '@/contexts';
import { FormModal, MultiSelect } from '@/components';
import { useUser } from '@/hooks';
import {
    PermissionsType,
    UserCreateAndUpdateType,
    UserItemType
} from '@/types';

const formSchema = z.object({
    assignStores: z.array(z.string()),
    email: z.string().email(),
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    // merchantGroupId: z.string().min(2),
    permissions: z.string().min(2)
    // selectStoreId: z.string().min(2)
});

function UserForm({
    data,
    permissions,
    openForm,
    setOpenForm,
    MerchantData,
    setSelectedRow,
    createUser,
    updateUser
}: {
    data?: UserItemType | undefined;
    permissions: PermissionsType;
    openForm: boolean;
    setOpenForm: (open: boolean) => void;
    MerchantData: any;
    setSelectedRow: (v: string | null) => void;
    createUser: any;
    updateUser: any;
}) {
    const { t } = useTranslation();

    const { stores, isLoading } = useStoreContext();

    const transformedStoresList = useMemo(() => {
        if (Array.isArray(stores) && stores.length > 0) {
            return stores.map((store) => ({
                value: store.storeId.toString(),
                label: store.storeName
            }));
        }
        return [];
    }, [stores]);

    const transformedPermissionList = useMemo(() => {
        if (permissions && Object.keys(permissions).length > 0) {
            return Object.keys(permissions)
                .map((key) => ({
                    value: permissions[key].groupId,
                    label: t(`user.permission-${key}`)
                }))
                .filter((item) => item.label !== 'Technician');
        }
        return [];
    }, [permissions]);

    // Set form values when the form is opened with data (update mode)
    useEffect(() => {
        if (data) {
            const stores = Object.keys(data.attributes.all_stores || {});
            form.setValue('assignStores', stores);
            form.setValue('email', data.email);
            form.setValue('firstName', data.firstName);
            form.setValue('lastName', data.lastName);
            form.setValue(
                'permissions',
                permissions[data.permission.replace('permission-', '')]
                    ?.groupId || ''
            );
        }
    }, [data]);

    // Reset form when the form is closed
    useEffect(() => {
        if (!openForm) {
            form.reset();
        }
    }, [openForm]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            assignStores: [],
            email: '',
            firstName: '',
            lastName: '',
            permissions: ''
        }
    });

    function onSubmit(values: z.infer<typeof formSchema>) {
        const userObject: UserCreateAndUpdateType = {
            assignStores: values.assignStores,
            firstName: values.firstName,
            lastName: values.lastName,
            permission: values.permissions
        };

        if (data) {
            // add userId to userObject
            userObject.userId = data.id;
            //find removed stores
            const stores = Object.keys(data.attributes.all_stores || {});
            const removedStores = stores?.filter(
                (store: string) => !values.assignStores.includes(store)
            );
            userObject.deassignStores = removedStores;
            // Check if the store data has been updated (no changes detected)
            const isUpdated = Object.keys(values).some(
                (key) =>
                    (values as any)[key as keyof typeof values] !==
                    (data as any)[key as keyof typeof data]
            );
            if (!isUpdated) {
                setOpenForm(false);
                console.log('No changes detected');
                return;
            }
            // console.log('userObject', userObject);

            updateUser.mutate(userObject, {
                onSuccess: () => {
                    setSelectedRow(null);
                    setOpenForm(false);
                }
            });
        } else {
            userObject.email = values.email;
            userObject.merchantGroup = MerchantData?.merchantGroup.id;
            userObject.selectStoreId = values.assignStores[0];
            createUser.mutate(userObject, {
                onSuccess: () => {
                    setSelectedRow(null);
                    setOpenForm(false);
                }
            });
        }
    }

    return (
        <FormModal
            open={openForm}
            setOpen={(open) => {
                setSelectedRow(null);
                setOpenForm(open);
            }}
            btnLabel={t('user.newUser')}
            formTitle={!!data ? t('user.editUser') : t('user.newUser')}
        >
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-4 mt-4"
                >
                    <FormField
                        control={form.control}
                        name="firstName"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="firstName"
                                    label={t('user.firstName') + ' *'}
                                    type="text"
                                    required
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="lastName"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="lastName"
                                    label={t('user.lastName') + ' *'}
                                    type="text"
                                    required
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="email"
                                    label={t('user.email') + ' *'}
                                    type="email"
                                    required
                                    disabled={!!data}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="assignStores"
                        render={({ field }) => (
                            <FormItem>
                                <MultiSelect
                                    options={transformedStoresList}
                                    defaultValue={field.value}
                                    onValueChange={field.onChange}
                                    label={t('wifi.stores')}
                                    placeholder={t('common.selectStore')}
                                    variant="inverted"
                                    animation={0}
                                    maxCount={3}
                                    className=" min-h-12 w-full border-gray-300 focus:border-primary-500"
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="permissions"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    statuses={transformedPermissionList}
                                    showPrefixIcon={false}
                                    placeholder={t('user.selectRole')}
                                    hideFilter={true}
                                    label={t('user.role') + ' *'}
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <Button
                        disabled={!!data && !form.formState.isDirty}
                        type="submit"
                    >
                        {t('common.submit')}
                    </Button>
                </form>
            </Form>
        </FormModal>
    );
}

export default UserForm;
