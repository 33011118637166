import { Button } from '@/components/ui/button';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@/components/ui/form';

import { ComboBoxResponsive } from '../../ui/combobox';
import { useEffect, useMemo, useState } from 'react';
import { useCloudOpening, useDetacherUnit } from '@/hooks';
import { OPENING_TYPES } from '@/hooks/data/Constants';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { StoreType } from '@/types';
import { useStoreContext } from '@/contexts';
import { Tag, TagInput } from 'emblor';
import { cn } from '@/utils';
import { Separator } from '@/components/ui/separator';
import { FloatingLabelInput } from '@/components/ui/floatingLabelInput';

const TagSchema = z.object({
    id: z.string(),
    text: z.string()
});
const formSchema = z.object({
    formType: z.enum(['withStoreId', 'withStoreForeignId']),
    storeId: z.string().optional(),
    storeForeignId: z.string().optional(),
    openingType: z.enum(['proximity', 'batch']),
    checkoutName: z.string().optional(),
    checkoutId: z.string().optional(),
    serialNumbers: z.array(TagSchema).optional(),
    deviceIds: z.array(TagSchema).optional(),
    accessIds: z.array(TagSchema).optional(),
    idListType: z.enum(['deviceIds', 'accessIds', 'serialNumbers']),
    receiptNumber: z.string().optional(),
    receiptDate: z.string().optional(),
    articleTotal: z.number().optional()
});

const FORM_TYPE_OPTIONS = [
    { value: 'withStoreId', label: 'cloudOpening.storeName' },
    { value: 'withStoreForeignId', label: 'cloudOpening.storeForeignId' }
];

const ID_LIST_TYPE_OPTIONS = [
    { value: 'serialNumbers', label: 'cloudOpening.serialNumbers' },
    { value: 'deviceIds', label: 'cloudOpening.deviceIds' },
    { value: 'accessIds', label: 'cloudOpening.accessIds' }
];

function OpenTagsForm({ setJobId }: { setJobId: (jobId: string) => void }) {
    const { t } = useTranslation();
    const [transformedStoresFIDList, setTransformedStoresFIDList] = useState<
        {
            value: string;
            label: string;
        }[]
    >([]);

    const [transformedCheckoutList, setTransformedCheckoutList] = useState<
        {
            value: string;
            label: string;
        }[]
    >([]);

    const {
        ForeignNames,
        openTags,
        isLoading: isLoadingForeignIds
    } = useCloudOpening({});
    const { stores, isLoading: fetchStoresLoading } = useStoreContext();

    const [tags, setTags] = useState<Tag[]>([]);
    const [status, setStatus] = useState('');
    const form = useForm<z.infer<typeof formSchema> & { serialNumber: Tag[] }>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            formType: 'withStoreId',
            storeForeignId: '',
            storeId: '',
            openingType: 'proximity',
            checkoutName: '',
            checkoutId: '',
            serialNumbers: [],
            deviceIds: [],
            accessIds: [],
            idListType: 'serialNumbers',
            receiptNumber: '',
            receiptDate: '',
            articleTotal: undefined
        }
    });

    const storeId = useWatch({ control: form.control, name: 'storeId' });
    // console.log('storeId:', storeId);
    const storeForeignId = useWatch({
        control: form.control,
        name: 'storeForeignId'
    });

    const formType = useWatch({ control: form.control, name: 'formType' });
    const idListType = useWatch({ control: form.control, name: 'idListType' });
    const openingType = useWatch({
        control: form.control,
        name: 'openingType'
    });

    const { DetacherUnits, isLoading: fetchDetacherUnitsLoading } =
        useDetacherUnit({
            storeId: storeId
        });

    // Transform stores into dropdown options using useMemo for optimization
    const transformedStoreOptions = useMemo(() => {
        if (formType !== 'withStoreId' || stores.length === 0) return [];

        if (stores?.length === 1)
            return [
                {
                    value: stores[0].storeId?.toString(),
                    label: stores[0].storeName + ' (' + stores[0].storeId + ')'
                }
            ];

        return stores.map((store: StoreType) => {
            return {
                value: store?.storeId?.toString(),
                label: store?.storeName + ' (ID:' + store?.storeId + ')'
            };
        });
    }, [stores, formType]);

    // set initial values in case of changing the formType
    useEffect(() => {
        if (formType === 'withStoreId') {
            if (stores?.length === 0) return;
            form.setValue('storeId', stores[0]?.storeId?.toString());
        } else {
            if (transformedStoresFIDList.length > 0) {
                form.setValue(
                    'storeForeignId',
                    transformedStoresFIDList[0]?.value
                );
            }
            if (transformedCheckoutList.length > 0) {
                form.setValue(
                    'checkoutName',
                    transformedCheckoutList[0]?.value
                );
            }
        }
    }, [stores, formType, storeForeignId]);

    // Transform Detacher Units into dropdown options using useMemo for optimization
    const transformedDUOptions = useMemo(() => {
        if (DetacherUnits?.length === 0) return [];
        const duOptions = DetacherUnits.filter((du) =>
            du?.mode?.startsWith('Cloud')
        ).map((du) => ({
            value: du?.name,
            label: du?.name
        }));
        // form.setValue('checkoutId', duOptions[0]?.value);
        return duOptions;
    }, [DetacherUnits, fetchDetacherUnitsLoading, formType]);

    // set initial values in case of changing the formType
    useEffect(() => {
        if (formType === 'withStoreId') {
            if (transformedDUOptions.length > 0) {
                form.setValue('checkoutId', transformedDUOptions[0]?.value);
            }
        }
    }, [formType, transformedDUOptions]);

    useEffect(() => {
        if (ForeignNames === undefined || formType !== 'withStoreForeignId')
            return;
        const storeForeignKeyOptions = Array.isArray(ForeignNames?.store_ids)
            ? ForeignNames?.store_ids
                  ?.filter((name): name is string => !!name)
                  .map((name: string) => ({
                      value: name,
                      label: name
                  }))
            : [];
        setTransformedStoresFIDList(storeForeignKeyOptions);

        // Only set the storeForeignId if it's not already set
        if (!storeForeignId && storeForeignKeyOptions.length > 0) {
            form.setValue('storeForeignId', storeForeignKeyOptions[0]?.value);
        }
    }, [ForeignNames, storeForeignId, form, formType]);

    useEffect(() => {
        const updateCheckoutList = (
            checkoutNames: { value: string; label: string }[]
        ) => {
            setTransformedCheckoutList(checkoutNames);
            form.setValue('checkoutName', checkoutNames[0]?.value);
        };
        if (!storeForeignId) return;
        const checkoutNames = ForeignNames?.checkout_names?.[storeForeignId];
        const checkoutOptions = Array.isArray(checkoutNames)
            ? checkoutNames
                  ?.filter((name): name is string => !!name)
                  .map((name: string) => ({
                      value: name,
                      label: name
                  }))
            : [];
        updateCheckoutList(checkoutOptions);
    }, [storeForeignId, ForeignNames?.checkout_names, form, formType]);

    function onSubmit(values: z.infer<typeof formSchema>) {
        const serialNumberList = values.serialNumbers?.map((tag) => tag.text);
        const deviceIdList = values.deviceIds?.map((tag) => tag.text);
        const accessIdList = values.accessIds?.map((tag) => tag.text);
        setStatus(t('cloudOpening.pending'));

        let params = {
            formType: values.formType as 'withStoreId' | 'withStoreForeignId',
            openingType: values.openingType,
            storeId: values.storeId,
            checkoutId: values.checkoutId,
            storeForeignId: values.storeForeignId,
            checkoutName: values.checkoutName,
            idListType: values.idListType,
            deviceIds: deviceIdList,
            accessIds: accessIdList,
            serialNumbers: serialNumberList,
            receiptNumber: values.receiptNumber,
            receiptDate: values.receiptDate,
            articleTotal: values.articleTotal
        };

        openTags(params, {
            onSuccess: (data) => {
                setJobId(data?.data?.Job_Id);
                setStatus(
                    t('cloudOpening.success') + ' ' + data?.data?.Api_Code
                );
            },
            onError: (error) => {
                console.log('error:', error);

                setStatus(
                    t('cloudOpening.failed') +
                        ' ' +
                        error?.response?.data?.Api_Code
                );
                setJobId(error.response?.data?.Job_Id);
            }
        });
    }

    return (
        <Form {...form}>
            <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-primary-500">
                    {t('cloudOpening.openTags')}
                </h2>
                <div
                    className={cn(
                        'text-sm text-slate-500 rounded-md px-2 py-1',
                        {
                            'bg-red-100': status.startsWith('Failed'),
                            'bg-yellow-100': status.startsWith('Pending'),
                            'bg-green-100': status.startsWith('Success')
                        }
                    )}
                >
                    <span
                        className={cn('font-semibold', {
                            'text-red-500': status.startsWith('Failed'),
                            'text-yellow-500': status.startsWith('Pending'),
                            'text-green-500': status.startsWith('Success')
                        })}
                    >
                        {' '}
                        {status}
                    </span>
                </div>
            </div>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col space-y-5"
            >
                <FormField
                    control={form.control}
                    name="formType"
                    render={({ field }) => (
                        <FormItem className="space-y-3 mb-3">
                            <FormLabel>
                                {t('cloudOpening.storeIdentifier')}
                            </FormLabel>
                            <FormControl>
                                <RadioGroup
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    className="flex  space-x-8"
                                >
                                    {FORM_TYPE_OPTIONS.map((option) => (
                                        <FormItem
                                            key={option.value}
                                            className="flex items-center space-x-3 space-y-0"
                                        >
                                            <FormControl>
                                                <RadioGroupItem
                                                    value={option.value}
                                                />
                                            </FormControl>
                                            <FormLabel className="font-normal">
                                                {t(option.label)}
                                            </FormLabel>
                                        </FormItem>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormField
                        control={form.control}
                        name={
                            formType === 'withStoreId'
                                ? 'storeId' // This is the store id
                                : 'storeForeignId' // This is the store Foreign id
                        }
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-white px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-white file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm hover:bg-white hover:border-gray-400"
                                    statuses={
                                        formType === 'withStoreId'
                                            ? transformedStoreOptions
                                            : transformedStoresFIDList
                                    }
                                    showPrefixIcon={false}
                                    backgroundColor="bg-white"
                                    value={field.value}
                                    onChange={field.onChange}
                                    placeholder={
                                        formType === 'withStoreId'
                                            ? fetchStoresLoading
                                                ? t('common.loading')
                                                : t('cloudOpening.selectStore')
                                            : isLoadingForeignIds
                                            ? t('common.loading')
                                            : t('cloudOpening.selectStore')
                                    }
                                    label={
                                        formType === 'withStoreId'
                                            ? t('cloudOpening.storeName')
                                            : t('cloudOpening.storeForeignId')
                                    }
                                    // {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name={
                            formType === 'withStoreId'
                                ? 'checkoutId'
                                : 'checkoutName'
                        }
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm hover:bg-white hover:border-gray-400"
                                    statuses={
                                        formType === 'withStoreId'
                                            ? transformedDUOptions
                                            : transformedCheckoutList
                                    }
                                    showPrefixIcon={false}
                                    backgroundColor="bg-white"
                                    placeholder={
                                        fetchDetacherUnitsLoading
                                            ? t('common.loading')
                                            : t('cloudOpening.selectCheckout')
                                    }
                                    label={
                                        formType === 'withStoreId'
                                            ? t('cloudOpening.checkoutId')
                                            : t('cloudOpening.checkoutName')
                                    }
                                    value={field.value}
                                    onChange={field.onChange}
                                    disabled={
                                        fetchDetacherUnitsLoading ||
                                        (formType === 'withStoreId' &&
                                            !form.watch('storeId'))
                                    }
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="openingType"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm hover:bg-white hover:border-gray-400"
                                    statuses={OPENING_TYPES}
                                    showPrefixIcon={false}
                                    backgroundColor="bg-white"
                                    placeholder={t('cloudOpening.openingType')}
                                    hideFilter={true}
                                    label={t('cloudOpening.openingType') + ' *'}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                {openingType === 'batch' && (
                    <div className="space-y-6">
                        <FormField
                            control={form.control}
                            name="idListType"
                            render={({ field }) => (
                                <FormItem className="space-y-3">
                                    <FormLabel>
                                        {t('cloudOpening.identifierType')}
                                    </FormLabel>
                                    <FormControl>
                                        <RadioGroup
                                            onValueChange={field.onChange}
                                            defaultValue={field.value}
                                            className="flex  space-x-8"
                                        >
                                            {ID_LIST_TYPE_OPTIONS.map(
                                                (option, index) => (
                                                    <FormItem
                                                        key={index}
                                                        className="flex items-center space-x-3 space-y-0"
                                                    >
                                                        <FormControl>
                                                            <RadioGroupItem
                                                                value={
                                                                    option.value
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormLabel className="font-normal">
                                                            {t(option.label)}
                                                        </FormLabel>
                                                    </FormItem>
                                                )
                                            )}
                                        </RadioGroup>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name={idListType}
                            render={({ field }) => (
                                <FormItem className="flex flex-col items-start ">
                                    <FormLabel className="text-left">
                                        {t(`cloudOpening.${idListType}`)}
                                    </FormLabel>
                                    <FormControl>
                                        <TagInput
                                            {...field}
                                            placeholder={t(
                                                'cloudOpening.enterAnItem'
                                            )}
                                            tags={tags}
                                            inputProps={{
                                                style: {
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    outline: 'none'
                                                }
                                            }}
                                            setTags={(newTags) => {
                                                const idType = idListType as
                                                    | 'deviceIds'
                                                    | 'accessIds'
                                                    | 'serialNumbers';
                                                setTags(newTags);
                                                form.setValue(
                                                    idType,
                                                    newTags as [Tag, ...Tag[]]
                                                );
                                            }}
                                            activeTagIndex={-1}
                                            setActiveTagIndex={() => {}}
                                        />
                                    </FormControl>
                                    <FormDescription>
                                        {t(
                                            'cloudOpening.enterAnItemDescription'
                                        )}
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                )}

                <div className="relative">
                    <div className="text-sm text-muted-foreground absolute left-3 -top-[3px] bg-white px-2">
                        {t('cloudOpening.optionalFields')}
                    </div>

                    <Separator className="my-2" />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <FormField
                        control={form.control}
                        name="receiptNumber"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="receiptNumber"
                                    label={t('cloudOpening.receiptNumber')}
                                    type="text"
                                    className="bg-white"
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="receiptDate"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="receiptDate"
                                    label={t('cloudOpening.receiptDate')}
                                    type="text"
                                    className="bg-white"
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="articleTotal"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="articleTotal"
                                    label={t('cloudOpening.articleTotal')}
                                    type="number"
                                    className="bg-white"
                                    onChange={(e) => {
                                        field.onChange(Number(e.target.value));
                                    }}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className="flex items-center justify-between">
                    <Button
                        disabled={
                            form.watch('openingType') === 'batch'
                                ? !form.watch('storeForeignId') ||
                                  !form.watch('checkoutName')
                                : !form.watch('checkoutId') ||
                                  !form.watch('storeId')
                        }
                        type="submit"
                        className="w-full md:w-1/4"
                    >
                        {t('cloudOpening.openTags')}
                    </Button>
                    <Button
                        className="text-slate-500 font-normal"
                        type="reset"
                        variant={'ghost'}
                        onClick={() => {
                            form.reset();
                            setTags([]);
                            setStatus('');
                        }}
                    >
                        {t('common.resetForm')}
                    </Button>
                </div>
            </form>
        </Form>
    );
}

export default OpenTagsForm;
