import React from 'react';
import {
    SmartTagsUsageOnAllPosChart,
    SmartTagsUsedAtPosChart,
    SmartTagsUsageOnAppPosHistoricalChart,
    TotalSensorsUsedOnCashiersHistoricalChart
} from './charts';
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-day-picker';
import HelpTooltip from '@/components/common/HelpTooltip';

function CheckoutsTab({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const { t } = useTranslation();

    return (
        <>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2 sm:gap-3 ">
                <div className="bg-white rounded-xl  mb-3  py-4 px-2">
                    <div className="flex justify-start items-center md:px-4 mt-2 gap-2">
                        <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left">
                            {t('analytics.sensorsUsedAtPOS')}
                        </h1>
                        <HelpTooltip
                            content={t('analytics.sensorsUsedAtPOSDescription')}
                        />
                    </div>

                    <SmartTagsUsedAtPosChart
                        dateRange={dateRange}
                        storeId={storeId}
                    />
                </div>
                <div className="bg-white rounded-xl  mb-3  py-4 px-2">
                    <div className="flex justify-start items-center md:px-4 mt-2 gap-2">
                        <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left">
                            {t('analytics.totalGroupedSensorUsageOnCashiers')}
                        </h1>
                        <HelpTooltip
                            content={t(
                                'analytics.totalGroupedSensorUsageOnCashiersDescription'
                            )}
                        />
                    </div>

                    <SmartTagsUsageOnAllPosChart
                        dateRange={dateRange}
                        storeId={storeId}
                    />
                </div>
            </div>

            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2 sm:gap-3 ">
                <div className="bg-white rounded-xl  mb-3  py-4 px-2">
                    <div className="flex justify-start items-center md:px-4 mt-2 gap-2">
                        <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left">
                            {t('analytics.sensorsUsedAtCashier')}
                        </h1>
                        <HelpTooltip
                            content={t(
                                'analytics.sensorsUsedAtCashierDescription'
                            )}
                        />
                    </div>

                    <SmartTagsUsageOnAppPosHistoricalChart
                        dateRange={dateRange}
                        storeId={storeId}
                    />
                </div>
                <div className="bg-white rounded-xl  mb-3  py-4 px-2">
                    <div className="flex justify-start items-center md:px-4 mt-2 gap-2">
                        <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left">
                            {t('analytics.totalSensorUsedOnCashiers')}
                        </h1>
                        <HelpTooltip
                            content={t(
                                'analytics.totalSensorUsedOnCashiersDescription'
                            )}
                        />
                    </div>

                    <TotalSensorsUsedOnCashiersHistoricalChart
                        dateRange={dateRange}
                        storeId={storeId}
                    />
                </div>
            </div>
        </>
    );
}

export default CheckoutsTab;
