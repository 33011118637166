import { BarChartSkeleton, Chart } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import { useSensorTypeUsageCashiersData } from '@/hooks';
import { subSeconds } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { dateFormatter } from '@/utils';

function SmartTagsUsageOnAllPosChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const { data, error, isLoading } = useSensorTypeUsageCashiersData({
        startDate: dateFormatter(dateRange?.from),
        endDate: dateRange?.to
            ? dateFormatter(subSeconds(dateRange.to, 1))
            : '',
        storeId
    });

    if (isLoading || !storeId)
        return (
            <div className="mt-6">
                <BarChartSkeleton />
            </div>
        );
    if (error) return <p>Error</p>;

    return (
        <div className="relative pt-2 ">
            <Chart
                type={'bar'}
                data={data?.values || []}
                options={getChartOptions(
                    data?.cashierNames || [],
                    'bar',
                    undefined,
                    true
                )}
            />
        </div>
    );
}

export default SmartTagsUsageOnAllPosChart;
