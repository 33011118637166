import { SMART_SENSORS_TYPES } from '@/hooks/data/Constants';
import { useTotalSensorsData } from '@/hooks/data/analytics/inventory/useTotalSensorsData';
import { DateRange } from 'react-day-picker';
import { subSeconds } from 'date-fns';
import { dateFormatter } from '@/utils';
import { Chart, BarChartSkeleton } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';

function NumberOfSmartTagsChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const { data, error, isLoading } = useTotalSensorsData({
        startDate: dateFormatter(dateRange?.from),
        endDate: dateRange?.to
            ? dateFormatter(subSeconds(dateRange.to, 1))
            : '',
        storeId: storeId!
    });

    if (isLoading || !storeId)
        return (
            <div className="mt-6">
                <BarChartSkeleton />
            </div>
        );
    if (error) return <p>Error</p>;

    return (
        <div className="relative pt-2 ">
            <Chart
                type={'bar'}
                data={data ? data?.sensorValues : []}
                options={getChartOptions(SMART_SENSORS_TYPES, 'bar')}
            />
        </div>
    );
}

export default NumberOfSmartTagsChart;
