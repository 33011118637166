export default {
    common: {
        moreDetails: 'Mehr Details',
        noResultsFound: 'Keine Ergebnisse gefunden',
        save: 'Speichern',
        cancel: 'Abbrechen',
        delete: 'Löschen',
        edit: 'Bearbeiten',
        continue: 'Fortsetzen',
        search: 'Suche',
        selectAnOption: 'Wählen Sie eine Option',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        previous: 'Zurück',
        next: 'Weiter',
        totalResults: 'Gesamtergebnisse',
        selectStore: 'Bitte wählen Sie eine Filiale aus',
        allStores: 'Alle Filialen',
        submit: 'Übermitteln',
        loading: 'Wird geladen ...',
        resetForm: 'Formular zurücksetzen'
    },
    login: {
        title: 'Anmelden',
        toContinueTo: 'um fortzufahren',
        email: 'E-Mail',
        password: 'Passwort',
        login: 'Anmelden',
        forgotPassword: 'Passwort vergessen?',
        register: 'Registrieren',
        rememberMe: 'Angemeldet bleiben',
        welcomeTo: 'Willkommen bei'
    },
    resetPassword: {
        title: 'Passwort zurücksetzen',
        subtitle:
            'Geben Sie die E-Mail Adresse ein, mit der Sie sich angemeldet haben.',
        email: 'E-Mail',
        send: 'Senden',
        backToLogin: 'Zurück zur Anmeldung',
        resetPassword: 'Passwort zurücksetzen',
        resetPasswordDesc:
            'Der Benutzer wird automatisch per E-Mail benachrichtigt.',
        userResetPasswordSuccessfully:
            'E-Mail zum Zurücksetzen des Passworts erfolgreich gesendet'
    },
    checkInbox: {
        title: 'Bitte Überprüfen Sie ihr Postfach',
        subtitle:
            'Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet.',
        backToLogin: 'Zurück zur Anmeldung'
    },
    dashboardMenu: {
        analytics: 'Analytik',
        sensors: 'Smart Tags',
        'smart-tags': 'Smart Tags',
        detacherUnits: 'Kassen',
        'detacher-units': 'Kassen',
        stores: 'Filialen',
        users: 'Benutzer',
        wifiConfigs: 'Wifi Konfigurationen',
        'wifi-configs': 'Wifi Konfigurationen',
        'admin-tools': 'Admin-Tools',
        adminTools: 'Admin-Tools',
        feedbackSupport: 'Feedback & Unterstützung'
    },
    header: {
        logout: 'Abmelden',
        changePassword: 'Passwort ändern'
    },
    analytics: {
        totalSensors: 'Smart Tags',
        totalQuantity: 'Gesamtanzahl',
        detacherUnits: 'Detacher Units',
        openedToday: 'Heute geöffnet',
        opened: 'Geöffnet',
        today: 'Heute',
        currentMonth: 'Aktueller Monat',
        lowBatteries: 'Niedriger Batteriestand',
        inventory: 'Inventar',
        checkouts: 'Kassen',
        totalUniqueSmartTags: 'Gesamtanzahl der Smart Tags',
        numberOfSensors: 'Anzahl der Smart Tags',
        insufficientDataTitle:
            'Unzureichende Daten zur Erstellung des Diagramms',
        insufficientDataDescription:
            'Bitte versuchen Sie, einen anderen Datumsbereich oder eine andere Filiale auszuwählen.',
        numberOfSensorsDescription:
            'Dieses Diagramm zeigt den aktuellen Bestand an Smart Tags in Ihrer Filiale',
        numberOfSensorsHistorical: 'Anzahl der Smart Tags (historisch)',
        numberOfSensorsHistoricalDescription:
            'Dieses Diagramm zeigt Ihren Smart-Tag-Bestand zu einem bestimmten Zeitpunkt',
        sensorsInUse: 'Verwendete Smart Tags',
        sensorsInUseDescription:
            'Dieses Diagramm zeigt die Anzahl der Smart-Tag-Öffnungen, kategorisiert nach Smart-Tag-Typ, und bietet Einblicke in deren Nutzungsverteilung',
        sensorsInUseHistorical: 'Smart Tags in Benutzung (historisch)',
        sensorsInUseHistoricalDescription:
            'Dieses Diagramm zeigt die Anzahl der Smart-Tag-Öffnungen im Zeitverlauf, kategorisiert nach Smart-Tag-Typ, um Nutzungstrends hervorzuheben.',
        sensorsUsedAtPOS: 'Ausgewählter Smart Tag-Typ beim Auschecken',
        sensorsUsedAtPOSDescription:
            'Dieses Diagramm zeigt die Anzahl der Smart-Tag-Öffnungen an dem ausgewählten Checkout und bietet Einblicke in deren Nutzungsverhalten, um Muster zu erkennen.',
        totalGroupedSensorUsageOnCashiers:
            'Insgesamt an den Kassen verwendete Smart Tags',
        totalGroupedSensorUsageOnCashiersDescription:
            'Dieses Diagramm zeigt die Anzahl der Smart-Tag-Öffnungen an jedem Checkout und bietet einen Vergleich zu verschiedenen Checkoutstandorten',
        sensorsUsedAtCashier: 'Ausgewähltes Auschecken nach Smart Tag-Typ',
        sensorsUsedAtCashierDescription:
            'Dieses Diagramm zeigt die Anzahl der Smart-Tag-Öffnungen pro Checkout im Zeitverlauf und bietet Einblicke in Nutzungstrends und Verhaltensmuster',
        totalSensorUsedOnCashiers:
            'Smart Tag Unlock by Check out Trends im Zeitverlauf',
        totalSensorUsedOnCashiersDescription:
            'Dieses Diagramm zeigt die Anzahl der Smart-Tag-Öffnungen an jedem Checkout im Zeitverlauf, kategorisiert nach Smart-Tag-Typ, für einen klaren Überblick über deren Nutzungsverhalten'
    },
    error: {
        error: 'Fehler',
        somethingWentWrong: 'Etwas ist schief gelaufen',
        login_failed: 'Anmeldung fehlgeschlagen',
        invalid_grant: 'Ungültige Benutzeranmeldeinformationen',
        email_is_required: 'E-Mail ist erforderlich',
        invalid_email: 'Ungültige E-Mail-Adresse',
        password_is_required: 'Passwort ist erforderlich',
        password_must_be_at_least_8_characters_long:
            'Passwort muss mindestens 8 Zeichen lang sein',
        password_must_include_at_least_one_uppercase_letter:
            'Passwort muss mindestens einen Großbuchstaben enthalten',
        password_must_include_at_least_one_lowercase_letter:
            'Passwort muss mindestens einen Kleinbuchstaben enthalten',
        password_must_include_at_least_one_digit:
            'Passwort muss mindestens eine Ziffer enthalten',
        password_must_include_at_least_one_special_character:
            'Passwort muss mindestens ein Sonderzeichen enthalten'
    },
    datePicker: {
        selectADateRange: 'Wählen Sie einen Datumsbereich',
        pickADate: 'Wählen Sie ein Datum',
        last7Days: 'Letzte 7 Tage',
        last30Days: 'Letzte 30 Tage',
        last90Days: 'Letzte 90 Tage',
        last365Days: 'Letzte 365 Tage',
        custom: 'Benutzerdefiniert'
    },
    stores: {
        store: 'Filiale',
        newStore: 'Neues Filiale',
        storeName: 'Filialname',
        foreignId: 'Fremd-ID',
        email: 'E-Mail',
        manager: 'Filialleiter',
        phoneNumber: 'Telefonnummer',
        storeAddress: 'Filialadresse',
        city: 'Stadt',
        address: 'Adresse',
        state: 'Bundesland',
        postcode: 'Postleitzahl',
        country: 'Land',
        timezone: 'Zeitzone',
        EASGateType: 'EAS Gate',
        connectionType: 'Verbindungsart',
        registres: 'Registrierkassen',
        users: 'Benutzer',
        status: 'Status',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        filterStores: 'Nach Filiale filtern ...',
        storeDetails: 'Details der Filiale',
        storeNameRequired: 'Filialname ist erforderlich',
        addressRequired: 'Adresse ist erforderlich',
        managerRequired: 'Filialleiter ist erforderlich',
        selectState: 'Bundesland auswählen',
        selectCountry: 'Land auswählen',
        selectCity: 'Stadt auswählen',
        storeCreateSuccess: 'Filiale erfolgreich erstellt',
        storeUpdateSuccess: 'Die Filiale wurde erfolgreich aktualisiert',
        storeDeleteSuccess: 'Filiale erfolgreich gelöscht',
        searchStore: 'Suche Filiale ...'
    },
    wifi: {
        newWifiConfig: 'Neue Wifi-Konfiguration',
        editWifiConfig: 'Wifi-Konfiguration bearbeiten',
        deleteWifiConfig: 'Wifi-Konfiguration löschen',
        configName: 'Konfigurationsname',
        password: 'Passwort',
        networkName: 'Netzwerkname',
        securityType: 'Sicherheitstyp',
        securityKey: 'Sicherheitsschlüssel',
        encryptionMethod: 'Verschlüsselungsmethode',
        identity: 'Identität',
        ssid: 'SSID',
        stores: 'Filialen',
        configNameRequired: 'Konfigurationsname ist erforderlich',
        networkNameRequired: 'Netzwerkname ist erforderlich',
        wifiCreateSuccess: 'Wifi-Konfiguration erfolgreich erstellt',
        wifiUpdateSuccess: 'Wifi-Konfiguration erfolgreich aktualisiert',
        wifiDeleteSuccess: 'Wifi-Konfiguration erfolgreich gelöscht',
        searchWifiConfig: 'Suche WiFi config ...'
    },
    detacherUnit: {
        name: 'Name',
        description: 'Beschreibung',
        foreignId: 'Fremd-ID',
        automaticOpening: 'Automatisches Öffnen',
        buttonActiveSeconds: 'Aktive Zeit',
        status: 'Status',
        sensitivity: 'Empfindlichkeit',
        merchantId: 'Händlernummer',
        mode: 'Modus',
        store: 'Filiale',
        zone: 'Zone',
        type: 'Typ',
        selectType: 'Typ auswählen',
        detacherUnitUpdateSuccessfully:
            'Das Update der Detacher Unit war erfolgreich',
        searchDetacherUnit: 'Suche Detacher-Units ....',
        low: 'Niedrig',
        medium: 'Mittel',
        high: 'Hoch',
        editDetacherUnit: 'Detacher-Einheit bearbeiten',
        selectZone: 'Zone auswählen'
    },
    smartTag: {
        serialNumber: 'Seriennummer',
        batteryLevel: 'Batteriestand',
        tagId: 'Tag-ID',
        tagType: 'Smart Tag-Typ',
        lastReported: 'Zuletzt gemeldet',
        firmwareVersion: 'Firmware-Version',
        searchSmartTag: 'Suche Smart Tags ...',
        editSmartTag: 'Smart Tag bearbeiten',
        smartTagUpdateSuccessfully: 'Smart Tag erfolgreich aktualisiert'
    },
    user: {
        id: 'ID',
        email: 'E-Mail',
        role: 'Rolle',
        selectRole: 'Benutzerberechtigung auswählen',
        stores: 'Filialen',
        status: 'Status',
        firstName: 'Vorname',
        lastName: 'Nachname',
        fullName: 'Vollständiger Name',
        searchUser: 'Suche Benutzer ...',
        editUser: 'Benutzer bearbeiten',
        userUpdateSuccessfully: 'Benutzer erfolgreich aktualisiert',
        newUser: 'Neuer Benutzer',
        userCreateSuccessfully: 'Benutzer erfolgreich erstellt',
        userDeleteSuccessfully: 'Benutzer erfolgreich gelöscht',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        verified: 'Geprüft',
        notEmailVerified: 'E-Mail ist nicht geprüft',
        createdTime: 'Erstellungszeit',
        'permission-basic-employee': 'Grundlegender Mitarbeiter',
        'permission-basic-employee-desc':
            'Hat nur Zugriff auf die Smart Tags Openings in der Händler-App',
        'permission-supervisor': 'Supervisor',
        'permission-supervisor-desc':
            'Verfügt über alle Berechtigungen sowohl im Dashboard als auch in der Merchant App.',
        'permission-advanced-employee': 'Beobachter',
        'permission-advanced-employee-desc':
            'Hat Zugriff auf die Registerkarte Analytics im Dashboard. Hat auch Zugriff auf Smart Tag Openings und Product Mapping in der Merchant App',
        'permission-manager': 'Filialleiter',
        'permission-manager-desc':
            'Hat Zugriff auf Analytics, Smart Tags und Checkouts im Dashboard. Sie haben alle Berechtigungen in der Händler-App',
        'permission-technician': 'Techniker',
        'permission-technician-desc':
            'Hat nur Zugriff auf die Detacher-Einheiten im Dashboard.'
    },
    message: {
        areYouSure: 'Sind Sie absolut sicher?',
        deleteStore:
            'Diese Aktion kann nicht rückgängig gemacht werden. Dadurch wird der Speicher dauerhaft gelöscht.',
        deleteWifiConfig:
            'Diese Aktion kann nicht rückgängig gemacht werden. Dadurch wird die WLAN-Konfiguration dauerhaft gelöscht.',
        deleteUser:
            'Diese Aktion kann nicht rückgängig gemacht werden. Dadurch wird der Benutzer dauerhaft gelöscht.'
    },
    filter: {
        filterBy: 'Filtern nach',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        permission: 'Berechtigung',
        serialNumber: 'Seriennummer',
        deviceId: 'Tag-ID'
    },
    '403': {
        title: 'Entschuldigung, Sie haben keinen Zugriff auf diese Seite.',
        description:
            'Sie haben keine Berechtigung, auf diese Seite zuzugreifen.'
    },
    '404': {
        title: 'Entschuldigung, die Seite konnte nicht gefunden werden.',
        description: 'Die Seite, nach der Sie suchen, existiert nicht.',
        goBack: 'Zurück'
    },
    cloudOpening: {
        cloudOpening: 'Cloud Opening',
        createJob: 'Job erstellen',
        openingType: 'Öffnungstyp',
        checkoutName: 'Kassierername',
        jobId: 'Job-ID',
        openTags: 'Tags öffnen',
        getJobStatus: 'Job-Status abrufen',
        jobStatus: 'Job-Status',
        orderId: 'Bestellnummer',
        deviceList: 'Geräteliste',
        successList: 'Erfolgsliste',
        failedList: 'Fehlliste',
        success: 'Erfolgreich',
        failed: 'Failed',
        pending: 'steht aus',
        storeName: 'Filialname',
        storeForeignId: 'Store Foreign ID',
        checkoutId: 'Kasse ID',
        selectStore: 'Filiale auswählen',
        selectCheckout: 'Kassierer auswählen',
        serialNumbers: 'Serial Numbers',
        deviceIds: 'Device IDs',
        details: 'Details',
        accessIds: 'Access IDs (EANs)',
        identifierType: 'Identifizierungstyp',
        storeIdentifier: 'Filialidentifikator',
        result: 'Ergebnis',
        timeNeeded: 'Benötigte Zeit',
        enterAnItem: 'Geben Sie einen Artikel ein',
        enterAnItemDescription:
            'Geben Sie ein Element ein und drücken Sie die Eingabetaste, um es der Liste hinzuzufügen.',
        optionalFields: 'Optionale Felder',
        receiptNumber: 'Belegnummer',
        receiptDate: 'Belegdatum',
        articleTotal: 'Artikel gesamt'
    },
    reports: {
        logReport: 'Berichte',
        downloadReport: 'herunterladen',
        createReport: 'Bericht erstellen',
        articleTotal: 'Article Total',
        checkoutName: 'Kasse Name',
        dateFinished: 'Finish Date',
        dateStarted: 'Start Date',
        internalStoreId: 'Internal Store ID',
        JobId: 'Job ID',
        jobStatus: 'Job Status',
        listFailed: 'List Failed',
        listSuccess: 'List Success',
        merchantId: 'Merchant ID',
        oid: 'Oreder ID',
        deviceUsed: 'Detacher Unit',
        receiptDate: 'Receipt Date',
        receiptNumber: 'Receipt Number',
        storeId: 'Store ID',
        timeNeeded: 'Time Needed',
        searchByJobId: 'Search by job ID'
    }
} as const;
