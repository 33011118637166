import { OpenTagsForm, JobStatusForm } from '@/components';
import { useState } from 'react';

function CloudOpeningTab() {
    const [jobId, setJobId] = useState('');
    return (
        <div className="flex flex-col  items-center h-full pt-4 p-4">
            <div className="flex flex-col space-y-4 w-full md:w-1/2 border border-slate-100 p-2 md:p-6 rounded-lg mb-4 bg-white">
                <OpenTagsForm setJobId={setJobId} />
            </div>
            <div className="flex flex-col space-y-4 w-full md:w-1/2 border border-slate-100  p-2 md:p-6 rounded-lg bg-white">
                <JobStatusForm jobId={jobId} />
            </div>
        </div>
    );
}

export default CloudOpeningTab;
