import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { UserCreateAndUpdateType, UserResponse } from '@/types';
import { useSession } from '@/contexts';
import { useTranslation } from 'react-i18next';
import { serviceAPI } from '@/utils';
import { toast } from 'react-toastify';

type ParamsType = {
    sort: 'asc' | 'desc' | '';
    filters: any;
    userId: string;
    storeId?: string;
    currentPage?: number;
    pageSize?: number;
};
export const useUser = (params: ParamsType) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { session, user } = useSession();
    // const [currentPage, setCurrentPage] = useState(0); // Default to page 0
    // const [pageSize, setPageSize] = useState(10); // Default to page size of 10
    const { currentPage, pageSize, filters, storeId, userId } = params;
    //Fetch Users
    const {
        data: MerchantData = [],
        isLoading: MerchantDataIsLoading,
        isError: MerchantDataError
    } = useQuery(
        ['MerchantData'],
        async () => {
            const params: {
                merchantId?: string;
            } = {
                merchantId: user?.merchantId
            };
            const response = await serviceAPI('user').get(
                '/getAllStoresGroups',
                { params }
            );

            if (response.status !== 200) {
                throw new Error('Failed to fetch Merchant Data');
            }

            return response.data;
        },
        {
            enabled: !!session?.access_token,
            staleTime: Infinity, // Ensures data never becomes "stale"
            cacheTime: 10 * 60 * 1000, // Keeps cache for 10 minutes

            onError: (error: any) => {
                toast.error(
                    error.response?.data?.error ||
                        'Failed to fetch Merchant Data'
                );
            }
        }
    );

    // Fetch Users
    const {
        data: accounts = {
            users: [],
            Total_Number_Of_Users: 0,
            available_permissions: []
        },
        isLoading,
        isError
    } = useQuery<UserResponse>({
        queryKey: ['Users', storeId, pageSize, currentPage, filters],
        queryFn: async () => {
            let queryString = `?merchantId=${user?.merchantId}&pageNumber=${currentPage}&numberOnPage=${pageSize}`;

            Object.entries(params).forEach(([key, value]) => {
                if (value !== undefined && value !== null && value !== '') {
                    queryString += `&${key}=${encodeURIComponent(value)}`;
                }
            });

            const response = await serviceAPI('user').get(queryString);

            // Check if the response is a redirect (302)
            if (response.status === 302) {
                throw new Error(
                    'Redirect detected: User may not be authenticated'
                );
            }

            if (
                response.request.responseURL.startsWith(
                    process.env.CALLBACK_URL as string
                )
            ) {
                // handle the case where the user is not authenticated
                const error = new Error('Unauthorized');
                (error as any).status = 401; // Attach a 401 status
                throw error;
            }

            const data = {
                users: response.data.Users,
                Total_Number_Of_Users: response.data.Total_Number_Of_Users,
                available_permissions: response.data.available_permissions
            };

            return data;
        },
        enabled:
            !!session?.access_token &&
            !!user &&
            !!MerchantData &&
            params.userId !== undefined,
        staleTime: Infinity, // Ensures data never becomes "stale"
        // cacheTime: 0, // Keeps cache for 10 minutes
        onError: (error: any) => {
            if (error.response?.status !== 401) {
                toast.error(
                    error.response?.data?.error ||
                        'Failed to fetch User Accounts'
                );
            }
        }
        // }
    });

    // Create User
    const createUser = useMutation(
        (newUser: UserCreateAndUpdateType): Promise<any> =>
            serviceAPI('user').post('', newUser),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['Users']);
                toast.success(t('user.userCreateSuccessfully'));
            },
            onError: (error: any) => {
                toast.error(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to create User'
                );
            }
        }
    );

    // Update User
    const updateUser = useMutation({
        mutationKey: ['Users'],
        mutationFn: (updatedUser: UserCreateAndUpdateType): Promise<any> =>
            serviceAPI('user').put('', updatedUser),

        onSuccess: async () => {
            await queryClient.invalidateQueries(['Users']);
            toast.success(t('user.userUpdateSuccessfully'));
        },
        onError: (error: any) => {
            toast.error(
                error.response?.data?.error ??
                    error.response?.data?.Api_Message ??
                    'Failed to update User'
            );
        }
    });

    // Delete User
    const deleteUser = useMutation(
        (userId: string): Promise<any> =>
            serviceAPI('user').delete(`/?userId=${userId}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['Users']);
                toast.success(t('deleteUser.userDeleteSuccessfully'));
            },
            onError: (error: any) => {
                toast.error(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to delete User'
                );
            }
        }
    );

    // Reset Password
    const resetPassword = useMutation(
        (userId: string): Promise<any> =>
            serviceAPI('user').get(`/resetPassword?userId=${userId}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['Users']);
                toast.success(t('resetPassword.passwordResetSuccessfully'));
            },
            onError: (error: any) => {
                toast.error(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to reset password'
                );
            }
        }
    );

    return {
        accounts,
        isLoading,
        isError,
        createUser,
        updateUser,
        currentPage,
        deleteUser,
        pageSize,
        MerchantData,
        resetPassword
    };
};
