import { useQuery } from 'react-query';
import { useSession } from '@/contexts';
import { serviceAPI } from '@/utils';

export function useOverviewData({ storeId }: { storeId: string | undefined }) {
    const { session, user } = useSession();

    if (!session) {
        throw new Error('No session found');
    }

    const accessToken = session?.access_token;
    // console.log('accessToken:', accessToken);

    return useQuery(
        ['overviewData', session?.access_token, storeId],
        async () => {
            const response = await serviceAPI('analytic').get(
                `/analyticsStatistics?store_id=${storeId}`
            );
            return response.data;
        },
        {
            enabled:
                !!accessToken &&
                !!session?.access_token &&
                !!user &&
                storeId !== undefined &&
                !isNaN(Number(storeId)),
            // keepPreviousData: true,
            retry: 2, // Optional: retry up to 2 times if the request fails
            staleTime: Infinity, // Mark the data as always fresh, no need for automatic refetch
            refetchOnWindowFocus: false, // Disable refetch when the window gains focus
            refetchOnReconnect: false, // Disable refetch when the network reconnects
            refetchInterval: false, // Disable periodic refetch
            onError: (err) => {
                console.log('Error fetching data', err);
                // Optionally, setError((err as Error).message) if you have a state for errors
            }
        }
    );
}
