import { ColumnDef } from '@tanstack/react-table';
import { Badge } from '@/components/ui/badge';
import SortHeader from '../../common/SortableHeader';
import clsx from 'clsx';

export const getLogReportColumns = (
    t: (key: string) => string
): ColumnDef<any>[] => [
    {
        accessorKey: 'job_id',
        header: ({ column }) => {
            return <SortHeader column={column} title={t('reports.JobId')} />;
        },
        cell: (row: any) => (
            <div className=" w-[200px] flex flex-wrap gap-2">
                {row.getValue('job_id')}
            </div>
        )
    },
    {
        accessorKey: 'merchant_id',
        header: ({ column }) => {
            return (
                <SortHeader column={column} title={t('reports.merchantId')} />
            );
        }
    },

    {
        accessorKey: 'internal_store_id',
        header: ({ column }) => {
            return (
                <SortHeader
                    column={column}
                    title={t('reports.internalStoreId')}
                />
            );
        }
    },
    {
        accessorKey: 'store_id',
        header: ({ column }) => {
            return <SortHeader column={column} title={t('reports.storeId')} />;
        }
    },
    {
        accessorKey: 'device_used',
        header: ({ column }) => {
            return (
                <SortHeader column={column} title={t('reports.deviceUsed')} />
            );
        }
    },

    {
        accessorKey: 'checkout_name',
        header: t('reports.checkoutName')
    },
    {
        accessorKey: 'date_started',
        header: ({ column }) => {
            return (
                <SortHeader column={column} title={t('reports.dateStarted')} />
            );
        }
    },
    {
        accessorKey: 'date_finished',
        header: ({ column }) => {
            return (
                <SortHeader column={column} title={t('reports.dateFinished')} />
            );
        }
    },
    {
        accessorKey: 'time_needed',
        header: t('reports.timeNeeded')
    },
    {
        accessorKey: 'job_status',
        header: t('reports.jobStatus'),
        cell: ({ row }) => {
            const status = row.getValue('job_status');

            return (
                <Badge
                    className={clsx(
                        'capitalize',
                        status === 'DONE' && 'bg-green-100 text-green-600',
                        status === 'FAILED' && 'bg-red-100 text-red-600'
                    )}
                >
                    {status ? (status as string) : 'N/A'}
                </Badge>
            );
        }
    },
    {
        accessorKey: 'article_total',
        header: t('reports.articleTotal')
    },

    {
        accessorKey: 'list_success',
        header: t('reports.listSuccess'),
        cell: (row: any) => (
            <div className=" w-[200px] flex flex-wrap gap-2">
                {row.getValue('list_success')}
            </div>
        )
    },
    {
        accessorKey: 'list_failed',
        header: t('reports.listFailed'),
        cell: (row: any) => (
            <div className=" w-[200px] flex flex-wrap gap-2">
                {row.getValue('listFailed')}
            </div>
        )
    },
    {
        accessorKey: 'receipt_date',
        header: t('reports.receiptDate')
    },
    {
        accessorKey: 'receipt_number',
        header: t('reports.receiptNumber')
    },

    {
        accessorKey: 'oid',
        header: t('reports.oid'),
        cell: (row: any) => (
            <div className=" w-[200px] flex flex-wrap gap-2">
                {row.getValue('iod')}
            </div>
        )
    }
];
