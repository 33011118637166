import { useQuery } from 'react-query';
import { fetchSmartTagTypesWithSelectedCashier } from '@/services';
import { useSession } from '@/contexts';

type QueryParams = {
    startDate: string;
    endDate: string;
    storeId: string;
    cashier: string;
};

export function useSensorTypesSelectedCashier(params: QueryParams) {
    const { session, user } = useSession();
    const { startDate, endDate, storeId, cashier } = params;

    if (!session) {
        throw new Error('No session found');
    }

    // if (!storeId) {
    //     throw new Error('No Store found');
    // }

    const accessToken = session?.access_token;
    return useQuery(
        ['smartTagUnlockTrendsData', startDate, endDate, storeId, cashier], // Unique query key
        () =>
            fetchSmartTagTypesWithSelectedCashier({
                startDate,
                endDate,
                storeId,
                cashier,
                accessToken
            }),
        {
            enabled:
                !!accessToken &&
                storeId !== undefined &&
                storeId !== null &&
                !!user &&
                !!startDate &&
                !!endDate && // Only run if accessToken is available
                !!cashier,
            // staleTime: 5 * 60 * 1000, // Optional: data is considered fresh for 5 minutes
            // cacheTime: 10 * 60 * 1000 // Optional: cache the data for 10 minutes
            staleTime: Infinity // Mark the data as always fresh, no need for automatic refetch
        }
    );
}
