import { useEffect, useState } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/components/ui/select';

type SelectProps = {
    items: { label: string; value: string }[];
    value: string;
    onChange: (value: string) => void;
};

function CustomSelect({ items, value, onChange }: SelectProps) {
    const [selectedValue, setSelectedValue] = useState(value);

    // console.log('value', value);

    // Ensure component only renders after mounting on the client
    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    // if (!isMounted) return null; // Avoid rendering on server-side

    return (
        <Select onValueChange={(e) => onChange(e)} value={selectedValue}>
            <SelectTrigger className="">
                <SelectValue placeholder="Theme" />
            </SelectTrigger>
            <SelectContent>
                {items.map((item, index) => (
                    <SelectItem key={index} value={item.value}>
                        {item.label}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}

export default CustomSelect;
