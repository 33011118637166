import {
    QueryClient,
    QueryClientProvider,
    MutationCache,
    QueryCache
} from 'react-query';
import { toast } from 'react-toastify';

const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: (error: any, query) => {
                if (query.queryKey) {
                    console.error(`Error in query [${query.queryKey}]:`, error);
                }
                // toast.error(
                //     error.message || 'An error occurred while fetching data'
                // );
            }
        }),
        mutationCache: new MutationCache({
            onError: (error: any, _, __, mutation) => {
                const { mutationKey } = mutation.options;
                console.error(`Error in mutation [${mutationKey}]:`, error);

                if (error.response?.data.Job_Id) {
                    return error;
                } else {
                    toast.error(error.message || 'Failed to process mutation');
                }
            }
        }),
        defaultOptions: {
            queries: {
                onError: (error: any) => {
                    toast.error(error.message || 'An error occurred');
                }
            },
            mutations: {
                onError: (error: any) => {
                    toast.error(error.message || 'Failed to process mutation');
                }
            }
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
};

export default ReactQueryProvider;
