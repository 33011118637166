import { useQuery } from 'react-query';
import { fetchUsedSensorsData } from '@/services';
import { useSession } from '@/contexts';
import { useTranslation } from 'react-i18next';

type QueryParams = {
    startDate: string;
    endDate: string;
    storeId: string;
};

export function useUsedSensorsData(params: QueryParams) {
    const { session, user } = useSession();
    const { startDate, endDate, storeId } = params;
    const { t } = useTranslation();

    if (!session) {
        throw new Error('No session found');
    }

    const accessToken = session?.access_token;
    return useQuery(
        ['sensorUsageSystemsData', startDate, endDate, storeId], // Unique query key
        () =>
            fetchUsedSensorsData({
                startDate,
                endDate,
                storeId,
                accessToken,
                labelName: t('analytics.totalQuantity')
            }),
        {
            enabled:
                !!accessToken &&
                storeId !== undefined &&
                storeId !== null &&
                !!user &&
                !!startDate &&
                !!endDate, // Only run if accessToken is available
            // staleTime: 5 * 60 * 1000, // Optional: data is considered fresh for 5 minutes
            // cacheTime: 10 * 60 * 1000 // Optional: cache the data for 10 minutes
            staleTime: Infinity // Mark the data as always fresh, no need for automatic refetch
        }
    );
}
