import axios from 'axios';
import config from './configs';

// Map of base URLs
const baseURLs: Record<string, string> = {
    device: config.REACT_APP_BACKEND_DEVICES_URL || '',
    analytic: config.REACT_APP_BACKEND_ANALYTICS_URL || '',
    user: config.REACT_APP_BACKEND_USERS_URL || '',
    payment: config.REACT_APP_BACKEND_PAYMENT_URL || ''
};

// Function to dynamically set the baseURL
const getBaseURL = (urlKey: string): string => {
    return baseURLs[urlKey] || baseURLs.default;
};

// Create Axios instance
const axiosInstance = axios.create({
    // headers: {
    //     'Content-Type': 'application/json'
    // },

    timeout: 5000
});

let isRefreshing = false; // Prevent multiple refresh requests
let refreshSubscribers: Array<(token: string) => void> = [];

// Notify all subscribers with the new token
const onRefreshed = (newToken: string) => {
    // ✅ Ensure the new token is globally set before retrying any requests
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${newToken}`;

    while (refreshSubscribers.length) {
        const callback = refreshSubscribers.shift();
        if (callback) {
            callback(newToken);
        }
    }
};

const addSubscriber = (callback: (newToken: string) => void) => {
    refreshSubscribers.push(callback);
};

const doRefreshToken = async () => {
    const refreshToken = !!sessionStorage.getItem('refresh_token')
        ? sessionStorage.getItem('refresh_token')
        : localStorage.getItem('refresh_token');
    if (!refreshToken) {
        throw new Error('Refresh token not available');
    }
    const keycloakConfig = new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: config.REACT_APP_AUTH_CLIENT_ID || '',
        refresh_token: refreshToken
    });

    try {
        const response = await axios.post(
            `${config.REACT_APP_AUTH_AUTHORITY}/protocol/openid-connect/token`,
            keycloakConfig,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
        const { access_token, refresh_token } = response.data;
        sessionStorage.setItem('access_token', access_token);
        sessionStorage.setItem('refresh_token', refresh_token);
        if (localStorage.getItem('access_token')) {
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
        }
        return access_token;
    } catch (error) {
        console.error('Failed to refresh token:', error);
        throw error;
    }
};

// Add request interceptor for Authorization header
axiosInstance.interceptors.request.use((config) => {
    const token =
        sessionStorage.getItem('access_token') ??
        localStorage.getItem('access_token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Add response interceptor for handling token expiry
axiosInstance.interceptors.response.use(
    (response) => {
        if (!response.status.toString().startsWith('2')) {
            return Promise.reject({
                response,
                message: `HTTP Error: ${response.status}`
            });
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Check if it's a network error and the request was authenticated
        const isAuthRequest = !!originalRequest.headers?.Authorization;

        // Handle token expiration (401)
        if (
            (error.response?.status === 401 ||
                error.message === 'Network Error' ||
                (error.code === 'ERR_NETWORK' && isAuthRequest)) &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true; // Prevent infinite retry loops

            console.log('Token expired. Attempting to refresh...');

            if (isRefreshing) {
                // Wait for the new token and retry request
                return new Promise((resolve) => {
                    addSubscriber((newToken) => {
                        originalRequest.headers[
                            'Authorization'
                        ] = `Bearer ${newToken}`;
                        resolve(axiosInstance(originalRequest));
                    });
                });
            }

            isRefreshing = true;

            try {
                const newAccessToken = await doRefreshToken();

                // Notify all subscribers with new token
                onRefreshed(newAccessToken);

                return axiosInstance(originalRequest); // Retry failed request
            } catch (refreshError) {
                console.error(
                    'Token refresh failed. Logging out the user.',
                    refreshError
                );
                // Optional: Redirect to login page
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        } else {
            return Promise.reject(error);
        }
    }
);

// Function to make requests with dynamic baseURL
const serviceAPI = (urlKey: string) => {
    axiosInstance.defaults.baseURL = getBaseURL(urlKey);
    return axiosInstance;
};

export default serviceAPI;
