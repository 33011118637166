import { BarChartSkeleton, Chart } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import { subSeconds } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { useSmartTagUnlockTrends } from '@/hooks/data/analytics/inventory/useSmartTagUnlockTrends';
import { dateFormatter } from '@/utils';
import { useDetacherUnit } from '@/hooks';

function SmartTagsInUseHistoricalChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const { data, error, isLoading } = useSmartTagUnlockTrends({
        startDate: dateFormatter(dateRange?.from),
        endDate: dateRange?.to
            ? dateFormatter(subSeconds(dateRange.to, 1))
            : '',
        storeId: storeId!
    });

    if (isLoading || !storeId)
        return (
            <div className="mt-6">
                <BarChartSkeleton />
            </div>
        );
    if (error) return <p>Error: </p>;

    return (
        <div className="relative pt-2  ">
            <Chart
                type={'line'}
                data={data?.smartTagsHistory || []}
                options={getChartOptions(data?.intervals || [], 'line')}
            />
        </div>
    );
}

export default SmartTagsInUseHistoricalChart;
