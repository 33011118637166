import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useSession } from '@/contexts'; // Adjust if needed
import { checkRoutePermission } from '@/utils';

const ProtectedRoute = () => {
    const { session, loading, user } = useSession();
    const location = useLocation();

    if (
        user &&
        !checkRoutePermission(location.pathname, user) &&
        location.pathname !== '/cloud-opening'
    ) {
        return <Navigate to="/403" />;
    }

    if (loading) return <p>Loading session...</p>;

    // Ensure we're not updating state while rendering
    return session?.access_token ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
