import React from 'react';
import config from '@/utils/configs';

const StagingBanner: React.FC = () => {
    const isStaging = config.ENV === 'dev'; // Adjust for your env variable

    if (!isStaging) return null;

    return (
        <div className="fixed top-0 left-0 w-full bg-[repeating-linear-gradient(-45deg,black,black_10px,yellow_10px,yellow_20px)]  h-2  py-1 z-[150] flex justify-center shadow-md">
            <div className="bg-white text-black text-center text-sm font-semibold py-1 flex self-center rounded-md px-4 mt-7 shadow-md  ">
                🚧 TRUNK 🚧
            </div>
        </div>
    );
};

export default StagingBanner;
