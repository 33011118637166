import React from 'react';
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger
} from '@/components/ui/tooltip';
import { CircleHelp } from 'lucide-react';

function HelpTooltip({ content }: { content: string }) {
    return (
        <Tooltip>
            <TooltipTrigger className="flex  items-center justify-start gap-2 cursor-help ">
                <CircleHelp className="h-4 w-4 text-primary-500" />
            </TooltipTrigger>
            <TooltipContent className="max-h-72 overflow-y-auto w-full md:w-96">
                {content}
            </TooltipContent>
        </Tooltip>
    );
}

export default HelpTooltip;
