import { BarChartSkeleton, Chart } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import { dateFormatter } from '@/utils';
import { DateRange } from 'react-day-picker';
import { useSmartTagCashierHistorical } from '@/hooks/data/analytics/checkouts/useSmartTagCashierHistorical';
import { subSeconds } from 'date-fns';
import { TriangleAlert } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function SmartTagsUsageOnAppPosHistoricalChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const { t } = useTranslation();
    const { data, error, isLoading } = useSmartTagCashierHistorical({
        startDate: dateFormatter(dateRange?.from),
        endDate: dateRange?.to
            ? dateFormatter(subSeconds(dateRange.to, 1))
            : '',
        storeId: storeId!
    });

    if (isLoading || !storeId || !data?.intervals || !data?.cashierHistory) {
        return (
            <div className="mt-6">
                <BarChartSkeleton />
            </div>
        );
    } else if (data?.cashierHistory.length === 0) {
        return (
            <div className="w-full h-96 flex flex-col items-center justify-center">
                <p className="bg-yellow-500 p-2 rounded-md flex items-center text-white">
                    <TriangleAlert className="w-6 h-6 text-white mr-2" />
                    {t('analytics.insufficientDataTitle')}
                </p>
                <p className="mt-2 text-sm text-gray-500">
                    {t('analytics.insufficientDataDescription')}
                </p>
            </div>
        );
    }

    console.log('data', data);

    return (
        <div className="relative pt-2 ">
            <Chart
                type={'line'}
                data={data?.cashierHistory || []}
                options={getChartOptions(data?.intervals || [], 'line')}
            />
        </div>
    );
}

export default SmartTagsUsageOnAppPosHistoricalChart;
